import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useGlobalShortcutsContext } from 'hooks/useGlobalShortcutsContext';
import { SidebarContextProvider } from 'hooks/useSidebarContext';

export const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const shortcutsContext = useGlobalShortcutsContext();

  const [open, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () =>
    setIsSidebarOpen((prevSidebarState) => !prevSidebarState);

  const openSidebar = useCallback(() => setIsSidebarOpen(true), []);

  const closeSidebar = useCallback(() => setIsSidebarOpen(false), []);

  useEffect(() => {
    shortcutsContext.setSidebarVisibilityHandler(() => toggleSidebar);

    return () => shortcutsContext.setSidebarVisibilityHandler(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SidebarContextProvider
      value={{
        open,
        openSidebar,
        closeSidebar
      }}
    >
      {children}
    </SidebarContextProvider>
  );
};

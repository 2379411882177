import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
import { downloadReport } from 'actions/reportsActions';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import reportLoadingImage from 'assets/report-loading.png';
import requestReportImage from 'assets/request-report.png';
import cn from 'classnames';
import { CommonTooltip, Tooltip } from 'components';
import { Report } from 'entities/Report.entity';
import { Routes } from 'enums';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

interface Props {
  loading?: boolean;
  data: Report;
}

export const ReportCard: FC<Props> = ({ data, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleViewReport = () => {
    mixpanel?.track(MixpanelEvents.ViewReportClick, {
      'Report URL': data.filename
    });
    navigate(Routes.CRMBoosterDetails.replace(':id', data.id));
  };

  const reportTitle = loading
    ? t('Page.Report.LoadingReportCard.Title')
    : data.title || '';

  const handleDownloadReport = () => {
    mixpanel?.track(MixpanelEvents.DownloadReportClick, {
      'Report URL': data.filename
    });
    downloadReport(data.id);
  };

  return (
    <div className={styles.container}>
      <div className={styles['title-container']}>
        <Tooltip title={reportTitle}>
          <h3 className={styles.title}>{reportTitle}</h3>
        </Tooltip>
        {!loading && (
          <DownloadIcon
            className={styles.download}
            onClick={handleDownloadReport}
          />
        )}
      </div>
      <img
        alt="report"
        className={styles.image}
        src={loading ? reportLoadingImage : requestReportImage}
      />
      {loading ? (
        <div className={styles.description}>
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
        </div>
      ) : (
        <CommonTooltip
          title={data.description || ''}
          className={cn(styles.description, 'overflowed-text-multiline')}
        />
      )}
      <div className={styles['footer-container']}>
        {loading ? (
          t('Page.Report.LoadingReportCard.Description')
        ) : (
          <Button
            fullWidth
            size="medium"
            type="submit"
            color="primary"
            variant="outlined"
            onClick={handleViewReport}
          >
            {t('Page.Report.ViewReport')}
          </Button>
        )}
      </div>
    </div>
  );
};

import { ApiError } from 'entities/ApiError.entity';
import { AuthApi } from 'services/API/Auth/AuthApi';

export interface SigninUserData {
  email: string;
  password: string;
}

export interface SignupUserData {
  email: string;
  password: string;
  firstName: string;
  company: string;
  gender: string;
  lastName: string;
  preferredLanguage: string;
  isSubscribedToNewsletter: boolean;
  isAgreedOnTermsAndConditions: boolean;
}

export interface SigninPayload {
  userData: SigninUserData;
  question?: string;
  appRedirectPath?: string;
  redirectUrl?: boolean;
}

export interface SignupPayload {
  userData: SignupUserData;
  question?: string;
  appRedirectPath?: string;
  shouldRedirect?: boolean;
}

export interface ResendEmailPayload {
  email: string;
  question?: string;
  appRedirectPath?: string;
}

export interface RestorePasswordPayload {
  email: string;
  question?: string;
  appRedirectPath?: string;
}

export interface ConfirmRestorePasswordPayload {
  password: string;
  token: string;
  question?: string;
  appRedirectPath?: string;
}

export const signIn = async (
  payload: Omit<SigninPayload, 'redirectUrl'>
): Promise<void> => {
  try {
    await AuthApi.signIn(payload);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const signUp = async (
  signupPayload: Omit<SignupPayload, 'shouldRedirect'>
): Promise<void> => {
  try {
    await AuthApi.signUp(signupPayload);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const confirmEmail = async (token: string): Promise<null> => {
  try {
    await AuthApi.confirmEmail(token);

    return null;
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateEmailConfirm = async (token: string): Promise<null> => {
  try {
    await AuthApi.updateEmailConfirm(token);

    return null;
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const resendEmail = async (
  payload: ResendEmailPayload
): Promise<void> => {
  try {
    await AuthApi.resendEmail(payload);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const restorePassword = async (
  payload: RestorePasswordPayload
): Promise<void> => {
  try {
    await AuthApi.restorePassword(payload);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const logout = async (): Promise<void> => {
  try {
    await AuthApi.logout();
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const confirmRestorePassword = async (
  payload: ConfirmRestorePasswordPayload
): Promise<void> => {
  try {
    await AuthApi.confirmRestorePassword(payload);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as CongratsIcon } from 'assets/congrats.svg';
import { Modal } from 'components';
import { Routes } from 'enums';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

interface Props {
  place: string;
  isOpen: boolean;
  onClose: () => void;
}
export const RequestFreeReportSuccessModal: FC<Props> = ({
  place,
  isOpen,
  onClose
}) => {
  const { chatId, dashboardId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCTAClick = () => {
    mixpanel?.track(MixpanelEvents.SuccessfulRequestForFreeSnippetCTAClick, {
      Place: place,
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
    navigate(Routes.Reports);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <CongratsIcon className={styles.icon} />

        <h3 className={styles.title}>
          {t('Page.RequestReportModal.Confirm.Title')}
        </h3>
        <p className={styles.subtitle}>
          {t('Page.RequestReportModal.Confirm.Subtitle')}
        </p>

        <div className={styles.actions}>
          <Button
            size="medium"
            color="primary"
            onClick={handleCTAClick}
            variant="contained"
          >
            {t('Page.RequestReportModal.Confirm.CTA')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

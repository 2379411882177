/* eslint-disable no-param-reassign */
import { CollapsableBadge } from 'components';
import { CollapsableBadgeThemes } from 'components/CollapsableBadge/CollapsableBadge';
import { DashboardCompany } from 'entities/DashboardCompany.entity';
import { Machine } from 'entities/Machine.entity';

import { CompanyNameCell } from './CompanyNameCell';

export const columns = [
  {
    width: 192,
    label: 'Page.Dashboard.ManufactureProfile.CompanyName',
    id: 'company_name',
    dataKey: 'company_name',
    renderCell: ({
      data,
      isExpanded,
      setExpandedRowName
    }: {
      data: DashboardCompany['company_name'];
      isExpanded?: boolean;
      setExpandedRowName: (id: string) => void;
    }) => (
      <CompanyNameCell
        title={data}
        isExpanded={isExpanded}
        setExpandedRowName={setExpandedRowName}
      />
    )
  },
  {
    width: 180,
    label: 'Page.Dashboard.ManufactureProfile.PhoneNumber',
    id: 'phone_numbers',
    dataKey: 'phone_numbers',
    renderCell: ({
      data,
      isExpanded
    }: {
      data: DashboardCompany['phone_numbers'];
      isExpanded?: boolean;
    }) => {
      data = data || [];

      const isStringArray = typeof data[0] === 'string';

      if (!isStringArray) {
        data = (data as { number: string; calling_code: string }[]).map(
          (item) => `${item.calling_code}${item.number}`
        );
      }

      return (
        <CollapsableBadge
          showBadge={false}
          data={data as string[]}
          maxVisibleItems={1}
          isExpanded={isExpanded}
          theme={CollapsableBadgeThemes.Light}
        />
      );
    }
  },
  {
    width: 240,
    label: 'Page.Dashboard.ManufactureProfile.CompanyEmail',
    id: 'emails',
    dataKey: 'contacts',
    renderCell: ({
      data,
      isExpanded
    }: {
      data: DashboardCompany['contacts'];
      isExpanded?: boolean;
    }) => (
      <CollapsableBadge
        showBadge={false}
        maxVisibleItems={1}
        isExpanded={isExpanded}
        theme={CollapsableBadgeThemes.Light}
        data={(data || [])?.map(({ email }) => email)}
      />
    )
  },
  {
    width: 200,
    label: 'Page.Dashboard.ManufactureProfile.Materials',
    id: 'materials',
    dataKey: 'materials',
    renderCell: ({
      data,
      isExpanded
    }: {
      data: string[];
      isExpanded?: boolean;
    }) => (
      <CollapsableBadge
        showBadge={false}
        data={data || []}
        maxVisibleItems={1}
        isExpanded={isExpanded}
        theme={CollapsableBadgeThemes.Light}
      />
    )
  },
  {
    width: 200,
    label: 'Page.Dashboard.ManufactureProfile.Technologies',
    id: 'technologies',
    dataKey: 'technologies',
    renderCell: ({
      data,
      isExpanded
    }: {
      data: DashboardCompany['technologies'];
      isExpanded?: boolean;
    }) => (
      <CollapsableBadge
        showBadge={false}
        data={data || []}
        maxVisibleItems={1}
        isExpanded={isExpanded}
        theme={CollapsableBadgeThemes.Light}
      />
    )
  },
  {
    width: 200,
    label: 'Page.Dashboard.ManufactureProfile.MachineBrand',
    id: 'machines_brand',
    dataKey: 'machines',
    renderCell: ({
      data,
      isExpanded
    }: {
      data: Machine[];
      isExpanded?: boolean;
    }) => (
      <CollapsableBadge
        showBadge={false}
        maxVisibleItems={1}
        isExpanded={isExpanded}
        theme={CollapsableBadgeThemes.Light}
        data={data?.map(({ details }) => details?.Brand)}
      />
    )
  },
  {
    width: 200,
    label: 'Page.Dashboard.ManufactureProfile.MachineModel',
    id: 'machines_model',
    dataKey: 'machines',
    renderCell: ({
      data,
      isExpanded
    }: {
      data: Machine[];
      isExpanded?: boolean;
    }) => (
      <CollapsableBadge
        showBadge={false}
        maxVisibleItems={1}
        isExpanded={isExpanded}
        theme={CollapsableBadgeThemes.Light}
        data={data?.map(({ details }) => details?.Model)}
      />
    )
  },
  {
    width: 164,
    label: 'Page.Dashboard.ManufactureProfile.Certification',
    id: 'certification',
    dataKey: 'certification',
    renderCell: ({
      data,
      isExpanded
    }: {
      data: DashboardCompany['certification'];
      isExpanded?: boolean;
    }) => (
      <CollapsableBadge
        showBadge={false}
        data={data || []}
        maxVisibleItems={1}
        isExpanded={isExpanded}
        theme={CollapsableBadgeThemes.Light}
      />
    )
  },
  {
    width: 92,
    label: 'Page.Dashboard.ManufactureProfile.Employees',
    id: 'employee_count',
    dataKey: 'employee_count'
  }
];

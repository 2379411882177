import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { TextField } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as CrossIcon } from 'assets/cross.svg';
import cn from 'classnames';
import { Keys } from 'enums/KeyboardKeys';

import styles from './styles.module.scss';

interface Props {
  value: string;
  label: string;
  placeholder?: string;
  inputClassName?: string;
  className?: string;
  onSubmit: (value: string) => void;
  toggleEditable: () => void;
}

export const EditableTitle: FC<Props> = ({
  value,
  className,
  inputClassName,
  placeholder,
  label,
  onSubmit,
  toggleEditable
}) => {
  const [inputValue, setInputValue] = useState<string>(value);

  const handleSubmit = useCallback(
    (e?: MouseEvent<HTMLSpanElement>) => {
      e?.stopPropagation();
      if (!inputValue) {
        setInputValue(value);
        onSubmit(value);
      } else {
        onSubmit(inputValue);
      }
    },
    [inputValue, onSubmit, value]
  );

  const handleClose = (e?: MouseEvent<HTMLSpanElement>) => {
    e?.stopPropagation();
    setInputValue('');
    toggleEditable();
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setInputValue(event.target.value);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.Enter) {
      handleSubmit();
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <TextField
        autoFocus
        size="small"
        value={inputValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        id={label.toLowerCase()}
        placeholder={placeholder}
        className={cn(styles.container, className)}
        inputProps={{
          maxLength: 110,
          className: cn(styles.input, inputClassName)
        }}
      />
      <div className={styles.actions}>
        <span onClick={handleClose}>
          <CrossIcon className={styles['action-icon']} />
        </span>
        <span onClick={handleSubmit}>
          <CheckIcon className={styles['action-icon']} />
        </span>
      </div>
    </>
  );
};

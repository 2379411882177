import { useMutation } from '@tanstack/react-query';
import { requestCRMBooster } from 'actions/salesforceActions';
import { ApiError } from 'entities/ApiError.entity';
import { useSnackbar } from 'hooks/useSnackbar';
import { CRMBoosterData } from 'services/API/Salesforce/ReportsApi';

export const useRequestCRMBooster = (onSuccess: () => void) => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError, CRMBoosterData>({
    mutationFn: (data) => requestCRMBooster({ data }),
    onSuccess() {
      onSuccess();
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};

import { Routes } from 'enums';
import { ProfileTabs } from 'views/Profile/Profile';

export const RoutesMetaConfig = {
  static: {
    [Routes.Root]: {
      title: 'Gieni',
      description:
        'Gieni is the first and only chat-based tool for the CNC manufacturing industry! The Gieni AI provides you with helpful deep market insights like never before. Our highly advanced CNC AIproject shows you what Gieni can already contribute to your decisions in this early stage,providing useful and valuable information.'
    },
    [Routes.Auth]: {
      title: 'Gieni - Authentication',
      description:
        "Access Gieni's versatile platform seamlessly by logging in or registering for an account. Enjoy the convenience of one-click access to your personalized dashboard or easily create a new account to begin your journey with Gieni."
    },
    [Routes.Login]: {
      title: 'Gieni - Login',
      description:
        'Seamlessly log into your Gieni account for streamlined access to a world of features. Experience hassle-free authentication and enjoy quick entry to your personalized dashboard.'
    },
    [Routes.CompleteUserInfo]: {
      title: 'Gieni - Complete User Info',
      description:
        'Complete user info identities effectively on Gieni to enhance security and trust. Validate user information with ease, ensuring a reliable user base for your platform.'
    },
    [Routes.Registration]: {
      title: 'Gieni - Sign Up',
      description:
        'Sign up for Gieni and unlock a host of powerful features tailored to your needs. Join our platform effortlessly and start exploring the possibilities today.'
    },
    [Routes.Confirm]: {
      title: 'Gieni - Confirm Email',
      description:
        'Confirm email addresses with Gieni effortlessly to ensure smooth communication and account verification. Strengthen security measures and maintain reliable user contact information.'
    },
    [Routes.Profile.replace(':tab?', ProfileTabs.Settings)]: {
      title: 'Gieni - Profile Settings',
      description:
        'Customize your Gieni experience with personalized profile settings. Tailor your preferences, update information, and manage your account effortlessly'
    },
    [Routes.Profile.replace(':tab?', ProfileTabs.Billing)]: {
      title: 'Gieni - Billing',
      description:
        'Manage your Gieni billing details efficiently and securely. Access transparent billing information, handle payments, and track expenses with ease.'
    },
    [Routes.NewChat]: {
      title: 'Gieni - Research',
      description:
        'Explore Gieni: Start conversations with our AI chatbot using predefined question suggestions or type your own for instant insights.'
    },
    [Routes.ResearchHistory]: {
      title: 'Gieni - Research History',
      description:
        'Explore the Research History on Gieni – View and manage all your created research chats in one convenient list. Stay organized and revisit your insights anytime.'
    },
    [Routes.SubscriptionPlans]: {
      title: 'Gieni - Pricing/Performance',
      description:
        "Explore Gieni's pricing plans and optimize performance for your specific needs. Discover competitive pricing options tailored to enhance your platform's efficiency and scalability."
    },
    [Routes.Checkout]: {
      title: 'Gieni - Checkout',
      description:
        "Streamline your checkout process with Gieni's efficient payment gateway. Simplify transactions, enhance security, and provide a seamless purchasing experience."
    },
    [Routes.OneTimePaymentCheckout]: {
      title: 'Gieni - One Time Payment',
      description:
        'Make hassle-free one-time payments on Gieni for instant access to premium features. Experience convenience and flexibility with secure payment solutions.'
    },
    [Routes.SubscriptionConfirm]: {
      title: 'Gieni - Subscription Confirmation',
      description:
        'Receive instant confirmation for your Gieni subscription, ensuring uninterrupted access to premium features. Stay informed about your subscription status effortlessly.'
    },
    [Routes.ReportConfirm]: {
      title: 'Gieni - Report Confirmation',
      description:
        'Receive instant confirmation for your Gieni report purchase, ensuring uninterrupted access to report.'
    },
    [Routes.SubscriptionUpgradeConfirm]: {
      title: 'Gieni - Subscription Upgrade Confirmation',
      description:
        'Upgrade your Gieni subscription with confidence and receive instant confirmation. Access enhanced features and enjoy an elevated user experience seamlessly.'
    },
    [Routes.CoinsPaymentConfirm]: {
      title: 'Gieni - Coins Payment Confirmation',
      description:
        'Confirm your coins payment on Gieni securely. Enjoy flexibility in your payment options and stay updated on your transaction status effortlessly.'
    },
    [Routes.NewDevelopments]: {
      title: 'Gieni - New Developments',
      description:
        "Stay informed about Gieni's latest developments and innovations. Explore new features, updates, and enhancements designed to enrich your user experience continuously."
    },
    [Routes.Notifications]: {
      title: 'Gieni - Notifications',
      description:
        'Stay updated with Gieni - Notifications. Manage, customize, and never miss important updates with our streamlined notification system.'
    },
    [Routes.ForgotPassword]: {
      title: 'Gieni - Forgot Password',
      description:
        'Enter your email address on Gieni to receive instructions on how to reset your password securely. Follow the steps provided to regain access to your account.'
    },
    [Routes.RestorePassword]: {
      title: 'Gieni - Restore Password',
      description:
        'Securely update your password on Gieni by entering and confirming your new password. Follow the instructions to ensure your account remains protected.'
    },
    [Routes.ConfirmRestorePassword]: {
      title: 'Gieni - Confirm Restore Password',
      description:
        'Confirm the successful restoration of your password on Gieni with this simple guide. Follow the steps provided to regain access to your account securely.'
    },
    [Routes.UpdateEmailConfirm]: {
      title: 'Gieni - Update Email Confirmation',
      description:
        'Confirm your updated email seamlessly with Gieni. Verify your new email address effortlessly and stay connected hassle-free. Complete the process securely in just a few clicks.'
    },
    [Routes.CRMBooster]: {
      title: 'Gieni - CRM Boosters',
      description:
        'Explore purchased data slices of companies to boost your CRM performance. Gain insights into industries, contacts, and more with Gieni.'
    },
    [Routes.Reports]: {
      title: 'Gieni - Reports',
      description:
        'Discover insightful and customizable reports with Gieni. Empower your decisions with real-time data analytics and intuitive visualizations.'
    }
  },
  dynamic: {
    chat: {
      title: 'Gieni - Chat',
      description:
        'Experience interactive conversations with AI-powered chat features on Gieni. Engage in dynamic exchanges, seek assistance, and explore innovative solutions effortlessly.'
    },
    dashboard: {
      title: 'Gieni - Dashboard',
      description:
        "Access insightful data visualizations, charts, and widgets on Gieni's dashboard, tailored to your queries to the AI chatbot. Gain valuable insights, track trends, and make informed decisions effortlessly."
    },
    crmBoosterDetails: {
      title: 'Gieni - Edit CRM Booster',
      description:
        'Easily edit and customize CRM data slices with Gieni. Filter by regions, industries, materials, technologies and machines to get more insights.'
    }
  }
};

import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal } from 'components';
import { useLanguage } from 'hooks';
import { useUserInfo } from 'hooks/api';
import { useRequestCRMBooster } from 'hooks/api/useRequestCRMBooster';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { RequestCRMBoosterSuccessModal } from './RequestCRMBoosterSuccessModal';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  industry?: string;
  question?: string;
}

export const RequestCRMBoosterModal: FC<Props> = ({
  isOpen,
  onClose,
  industry,
  question
}) => {
  const preferredLanguage = useLanguage();
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const { chatId, dashboardId } = useParams();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const getTrackPlace = () => {
    const { pathname } = window.location;
    let place = '-';

    if (pathname.includes('chat')) place = 'Chat';
    if (pathname.includes('dashboard')) place = 'Dashboard';

    return place;
  };

  const onSuccess = () => {
    mixpanel?.track(MixpanelEvents.SuccessfulRequestCRMBooster, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
    onClose();
    setIsSuccessModalOpen(true);
  };

  const { mutate: requestCRMBooster } = useRequestCRMBooster(onSuccess);

  const closeSuccessModal = useCallback(() => {
    setIsSuccessModalOpen(false);
  }, []);

  const onClick = () => {
    mixpanel?.track(MixpanelEvents.RequestCRMBooster, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
    onClose();
    requestCRMBooster({
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName || '',
      email: userInfo?.email || '',
      industryCustom: industry || '',
      additionalInfo: question || '',
      lang: preferredLanguage as 'en' | 'de',
      isAgreedOnTermsAndConditions: true
    });
  };

  return (
    <>
      <RequestCRMBoosterSuccessModal
        place={getTrackPlace()}
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleClassName={styles.title}
        title={t('Page.RequestCRMBoosterModal.Title')}
      >
        <p className={styles.subtitle}>
          {t('Page.RequestCRMBoosterModal.Subtitle')}
        </p>

        <div className={styles['main-content']}>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Page.RequestCRMBoosterModal.Industry')}
            </span>
            <TextareaAutosize
              disabled
              maxRows={1}
              value={industry}
              className={styles.container}
            />
          </div>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Page.RequestCRMBoosterModal.AdditionalInformation')}
            </span>
            <TextareaAutosize
              disabled
              minRows={2}
              value={question}
              className={styles.container}
            />
          </div>
          <p className={cn(styles.subtitle, styles.indented)}>
            {t('Page.RequestCRMBoosterModal.AdditionalInfo', {
              pricePerRow: '€1.5',
              amount: '100',
              totalPrice: '€150'
            })}
          </p>
        </div>

        <div className={styles.buttons}>
          <Button
            fullWidth
            size="medium"
            type="submit"
            color="primary"
            variant="outlined"
            onClick={onClose}
            className={styles.cancel}
          >
            {t('Page.RequestCRMBoosterModal.Cancel')}
          </Button>

          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={onClick}
            className={styles.submit}
          >
            {t('Page.RequestCRMBoosterModal.RequestCRMBooster')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

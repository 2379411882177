import { useMutation } from '@tanstack/react-query';
import { getReportPaymentSecret } from 'actions/paymentsActions';
import { ApiError } from 'entities/ApiError.entity';
import { useSnackbar } from 'hooks/useSnackbar';

export const useCreateReport = (onSuccess: (secret: string) => void) => {
  const snackbar = useSnackbar();

  return useMutation<
    { clientSecret: string },
    ApiError,
    {
      industry?: string;
      question?: string;
    }
  >({
    mutationFn: ({ industry, question }) =>
      getReportPaymentSecret({ industry, question }),
    onSuccess(data) {
      onSuccess(data.clientSecret);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};

import { createContext, useContext } from 'react';

interface SidebarContextData {
  open: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
}

export const sidebarContext = createContext<SidebarContextData>({
  open: false,
  openSidebar: () => {},
  closeSidebar: () => {}
});

export const SidebarContextProvider = sidebarContext.Provider;

export const useSidebarContext = (): SidebarContextData =>
  useContext(sidebarContext);

import React, { FC, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@mui/material';
import { ReactComponent as CrossIcon } from 'assets/cross-circle.svg';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import { ReactComponent as MaximizeIcon } from 'assets/maximize.svg';
import { ReactComponent as MinimizeIcon } from 'assets/minimize.svg';
import cn from 'classnames';
import { CommonTooltip } from 'components';
import { FilterTypeColor, FilterTypeLabel } from 'enums/FilterType.enum';

import styles from './styles.module.scss';

export interface FilterItem {
  name: string | number;
  typeName: string;
}
interface Props {
  className?: string;
  children?: ReactNode;
  data?: FilterItem[];
  disableRemove?: boolean;
  onRemoveFilter: (row: FilterItem) => void;
}

export const FloatingFilter: FC<Props> = ({
  data = [],
  children,
  onRemoveFilter,
  disableRemove,
  className
}) => {
  const { t } = useTranslation();
  const [expanded, setIsExpanded] = useState(false);

  const expandFilter = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const collapseFilter = useCallback(() => setIsExpanded(false), []);

  return (
    <div className={cn(styles.container, className)}>
      {children}
      <div className={styles.header}>
        <div className={styles['title-container']}>
          <FilterIcon className={styles['filter-icon']} />
          <span className={styles.title}>
            {t('Page.Dashboard.AppliedFilters', {
              amount: data.length
            })}
          </span>
        </div>
        {expanded ? (
          <MaximizeIcon
            onClick={collapseFilter}
            className={styles['expand-icon']}
          />
        ) : (
          <MinimizeIcon
            onClick={expandFilter}
            className={styles['expand-icon']}
          />
        )}
      </div>
      <Collapse unmountOnExit in={expanded}>
        <div className={styles.content}>
          {data.map((row) => (
            <div key={row.name} className={styles.row}>
              <div className={styles['row-content']}>
                <div
                  className={styles.color}
                  style={{
                    // @ts-expect-error type error
                    backgroundColor: FilterTypeColor[row.typeName]
                  }}
                />
                <CommonTooltip
                  className={cn(styles['row-title'], 'overflowed-text')}
                  title={`${
                    // @ts-expect-error type error
                    t(FilterTypeLabel[row.typeName])
                  }:`}
                />
                <CommonTooltip
                  title={row.name.toString()}
                  className={cn(styles['row-value'], 'overflowed-text')}
                />
              </div>
              <CrossIcon
                className={cn(
                  styles['cross-icon'],
                  disableRemove && styles.disabled
                )}
                onClick={() => onRemoveFilter(row)}
              />
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

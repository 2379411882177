import { FC, useMemo } from 'react';
import { FeatureCollection } from 'geojson';
import { useFetchRegionsMapData } from 'hooks/api/useFetchRegionsMapData';
import { FillLayer } from 'mapbox-gl';
import type { SelectedFilterItem } from 'types';

import { DEFAULT_REGIONS_LAYER_ID } from '../constants/mapSettings';
import { MapLayer } from '../MapLayer';

interface Props {
  codes: string[];
  selectedRegion: SelectedFilterItem;
}

export const FilterMapLayer: FC<Props> = ({ codes, selectedRegion }) => {
  const { data: regionsData } = useFetchRegionsMapData();

  const layerData: FillLayer = {
    id: DEFAULT_REGIONS_LAYER_ID,
    type: 'fill',
    paint: {
      'fill-color': '#ffa51c',
      'fill-opacity': [
        'case',
        ['==', ['get', 'code'], selectedRegion?.code || ''],
        0.6,
        0.15
      ]
    }
  };

  const sourceData = useMemo(() => {
    const processedRegionsData: FeatureCollection = {
      type: 'FeatureCollection',
      features: []
    };

    if (regionsData?.length) {
      regionsData.forEach((item: any) => {
        if (codes.includes(item.code)) {
          const properties = { ...item };
          const geometry = JSON.parse(item.geom);

          delete properties.geom;

          processedRegionsData.features.push({
            type: 'Feature',
            geometry,
            properties
          });
        }
      });
    }

    return processedRegionsData;
  }, [regionsData, codes]);

  return <MapLayer sourceData={sourceData} layerData={layerData} />;
};

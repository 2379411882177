import { ApiError } from 'entities/ApiError.entity';
import { Report } from 'entities/Report.entity';
import {
  FetchReportsFilters,
  ReportsApi
} from 'services/API/Reports/ReportsApi';

export const fetchReports = async (
  filters: FetchReportsFilters
): Promise<Report[]> => {
  try {
    const response = await ReportsApi.fetchReports(filters);

    return response.data.map(Report.deserialize);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const downloadReport = async (id: string): Promise<void> => {
  try {
    await ReportsApi.downloadReport(id);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const checkFreeSnippetAvailability = async (): Promise<boolean> => {
  try {
    const response = await ReportsApi.checkFreeSnippetAvailability();
    const isExist = response.headers.isexist;

    return isExist === 'false';
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const requestFreeSnippet = async ({
  industry,
  question
}: {
  question: string;
  industry?: string;
}): Promise<void> => {
  try {
    await ReportsApi.requestFreeSnippet({ industry, question });
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

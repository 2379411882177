import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { Routes } from 'enums';
import { useUserCoins, useUserInfo } from 'hooks/api';
import { ProfileTabs } from 'views/Profile/Profile';

import styles from './styles.module.scss';

interface Props {
  collapsed: boolean;
}

export const DashboardsInfo: FC<Props> = ({ collapsed }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: userCoins } = useUserCoins();
  const { data: userInfo } = useUserInfo();
  const previousCountValue = useRef<number | undefined>();
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (
      userCoins?.count &&
      previousCountValue?.current &&
      userCoins?.count !== previousCountValue?.current
    ) {
      setIsAnimating(true);
    }

    previousCountValue.current = userCoins?.count;
  }, [userCoins?.count]);

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  const goToBilling = () => {
    navigate(Routes.Profile.replace(':tab?', ProfileTabs.Billing));
  };

  const isFreeUserHasCoins = !!userCoins?.count && userInfo?.isFreeUser;

  return (
    <div className={styles.container}>
      <div
        className={cn(styles['title-container'], collapsed && styles.collapsed)}
      >
        {!userCoins?.count && userInfo?.isFreeUser && (
          <Link
            className={styles['recharge-dashboards']}
            to={Routes.SubscriptionPlans}
          >
            {t('Common.RechargeDashboards')}
          </Link>
        )}
        {isFreeUserHasCoins && (
          <span className={styles.title}>
            {t('Common.Sidebar.AvailableDashboards')}
          </span>
        )}
        <span onClick={goToBilling} className={styles.link}>
          {t('Common.Sidebar.ManagePlan')}
        </span>
      </div>
      {isFreeUserHasCoins && (
        <div
          onAnimationEnd={handleAnimationEnd}
          className={cn(
            styles.badge,
            isAnimating && styles['counter-animating']
          )}
        >
          {userCoins?.count}
        </div>
      )}
    </div>
  );
};

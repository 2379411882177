import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  createNestedDashboard,
  fetchNestedDashboards
} from 'actions/dashboardActions';
import { trackActivity } from 'actions/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import {
  DashboardLimitTimeRanges,
  InsightsType,
  queryKeys,
  Routes
} from 'enums';
import { ApiErrors } from 'enums/ApiErrors.enum';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';
import { NestedDashboardPayload } from 'services/API/Dashboard/DashboardApi';

export const useCreatedNestedDashboard = ({
  onSuccess,
  showNoCoinsModal,
  showVariationLimitModal
}: {
  onSuccess?: () => void;
  showNoCoinsModal?: () => void;
  showVariationLimitModal: () => void;
}) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return useMutation<
    { dashboardId: string },
    ApiError,
    {
      data: Array<NestedDashboardPayload>;
      chatId: string;
      dashboardId: string;
      originDashboardId?: string;
    }
  >({
    mutationFn: ({ data, dashboardId }) =>
      createNestedDashboard({ dashboardId, data }),
    onSuccess(newDashboard, { chatId, dashboardId, originDashboardId }) {
      navigate(
        Routes.Dashboard.replace(
          ':dashboardId',
          newDashboard.dashboardId
        ).replace(':chatId', chatId)
      );
      if (onSuccess) onSuccess();

      const dashboardData = queryClient.getQueryData(
        queryKeys.dashboardDetails(dashboardId)
      ) as DashboardData;

      const dashboardsHistoryData = dashboardData?.widgets?.find(
        ({ type }) => type === InsightsType.DashboardHistory
      );

      const isExistingDashboard = dashboardsHistoryData?.params?.data?.find(
        ({ id }) => id === newDashboard.dashboardId
      );

      if (!isExistingDashboard) {
        trackActivity();
      }

      if (originDashboardId) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.nestedDashboards(originDashboardId)
        });
        queryClient.fetchQuery({
          queryKey: queryKeys.nestedDashboards(originDashboardId),
          queryFn: () => fetchNestedDashboards(originDashboardId)
        });
      }
    },
    onError(error) {
      if (error?.message === ApiErrors.PremiumMonthlyDashboardsLimit) {
        // @ts-expect-error error type
        window?.openDashboardLimitModal(DashboardLimitTimeRanges.Monthly);
      } else if (error?.message === ApiErrors.PremiumDailyDashboardsLimit) {
        // @ts-expect-error error type
        window?.openDashboardLimitModal(DashboardLimitTimeRanges.Daily);
      } else if (error?.message === ApiErrors.NestedDashboardsLimit) {
        showVariationLimitModal();
      } else if (
        error?.message === ApiErrors.InsufficientDashboards &&
        showNoCoinsModal
      ) {
        showNoCoinsModal();
      } else if (error?.message === ApiErrors.NoFilteredDashboardData) {
        snackbar.warning.commonWarning(
          t('Notifications.NoResultsDashboardWarning')
        );
      } else {
        snackbar.error.commonError(error);
      }
    }
  });
};

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalShortcutsProvider } from 'components/GlobalShortcutsProvider';
import { ToastProvider } from 'components/ToastProvider';
import { AuthProvider } from 'views/AuthProvider';

import './i18n';
import './mixpanel';
import './sentry';

import App from './App';
import { SidebarProvider } from './components/SidebarProvider';
import { theme } from './muiTheme';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import 'styles/atomic/index.scss';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <ToastProvider />
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <GlobalShortcutsProvider>
              <SidebarProvider>
                <App />
              </SidebarProvider>
            </GlobalShortcutsProvider>
          </AuthProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

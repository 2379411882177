import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as HamburgerIcon } from 'assets/HamburgerIcon.svg';
import { ReactComponent as LogoIcon } from 'assets/logo-inverted.svg';
import cn from 'classnames';
import { Routes } from 'enums/Routes';
import {
  useAuthContext,
  useIsExternalUserDashboard,
  useMobile,
  useSidebarContext,
  useTablet
} from 'hooks';
import { useUserInfo } from 'hooks/api';

import { HamburgerMenu } from './HamburgerMenu';

import styles from './styles.module.scss';

const limitedHeaderRoutes = [
  Routes.Checkout,
  Routes.OneTimePaymentCheckout,
  Routes.ReportCheckout,
  Routes.Auth,
  Routes.Login,
  Routes.Registration,
  Routes.CompleteUserInfo
];

export const Header: FC = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthContext();
  const { data: userInfo } = useUserInfo();
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isDashboardPage = /dashboard/.test(location.pathname);
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { open, openSidebar, closeSidebar } = useSidebarContext();

  const showOnlyLogo = limitedHeaderRoutes.includes(
    location.pathname as Routes
  );

  const isUserVerified = userInfo?.hasCompleteInfo && isAuthenticated;
  const showExtraData = isUserVerified && !showOnlyLogo;

  const toggleSidebar = () => {
    if (open) {
      closeSidebar();
    } else {
      openSidebar();
    }
  };

  return (
    <>
      <header
        className={cn(
          styles.header,
          isExternalUserDashboard && 'not-displayed',
          isDashboardPage && styles['dashboard-header']
        )}
      >
        <div className={styles['logo-container']}>
          <Link
            to={Routes.NewChat}
            className={cn(
              styles.logo,
              (showOnlyLogo || isTablet || isMobile) && styles.visible
            )}
          >
            <LogoIcon />
          </Link>

          {!showOnlyLogo && (
            <HamburgerIcon
              onClick={toggleSidebar}
              className={styles['hamburger-icon']}
            />
          )}
        </div>

        <div className={styles.navigation}>
          {showExtraData && <HamburgerMenu />}
        </div>
      </header>
    </>
  );
};

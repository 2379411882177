import { fetchNestedDashboards } from 'actions/dashboardActions';
import { queryKeys } from 'enums';
import { queryClient } from 'index';

export const useInvalidateChatQueries = () => {
  return (dashboardId: string) => {
    if (dashboardId) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.nestedDashboards(dashboardId)
      });
      queryClient.fetchQuery({
        queryKey: queryKeys.nestedDashboards(dashboardId),
        queryFn: () => fetchNestedDashboards(dashboardId)
      });
    }
  };
};

import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { useSidebarContext } from 'hooks/useSidebarContext';

import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

export const AuthenticatedRoutesLayout: FC<Props> = ({ children }) => {
  const { open } = useSidebarContext();

  return (
    <div className={cn(styles.content, open && styles.expanded)}>
      {children}
    </div>
  );
};

import * as d3 from 'd3';
import { BaseType } from 'd3-selection';
import type { SelectedFilterItem } from 'types';

export interface ZoomableSunburstData {
  name: string;
  code?: string;
  value?: number;
  children?: Array<ZoomableSunburstData> | null;
}

export const renderZoomableSunburstChart = ({
  width,
  data,
  isMobile,
  svgSelection,
  preferredLanguage,
  selectItem,
  translations
}: {
  width: number;
  isMobile: boolean;
  preferredLanguage: string;
  data: ZoomableSunburstData;
  translations: {
    backTo: string;
    prevLevel: string;
  };
  selectItem: (selectedItem: SelectedFilterItem) => void;
  svgSelection: d3.Selection<SVGGElement | null, unknown, null, undefined>;
}) => {
  svgSelection.selectAll('*').remove();

  const radius = width / 6;
  const innerRadiusModifierScale = d3.scaleLinear([1, 2, 3, 4], [1, 1.3, 1]);
  const outerRadiusModifierScale = d3.scaleLinear([1, 2, 3, 4], [1.3, 1, 1]);
  const heightRadiusModifierScale = d3.scaleLinear([1, 2, 3], [0.6, 2, 1]);
  const yMidRadiusModifierScale = d3.scaleLinear([1, 2, 3], [1.15, 1.11, 1]);

  const color = d3.scaleOrdinal<string>(
    d3.quantize(
      d3.interpolateRgbBasis(['#ffe0b0', '#fb8a00']),
      (data.children?.length || 0) + 1
    )
  );

  const getTruncatedText = (text: string, height: number) => {
    const charWidth = 6;
    const maxChars = height / charWidth;

    return text.length > maxChars
      ? `${text.slice(0, maxChars - 3).trimEnd()}...`
      : text;
  };

  const calculateDimensions = (
    d: d3.HierarchyRectangularNode<ZoomableSunburstData>
  ) => {
    const heightRadiusModifier = heightRadiusModifierScale(d.y0);
    const yMidRadiusModifier = yMidRadiusModifierScale(d.y0);

    const xMid = (d.x0 + d.x1) / 2;
    const yMid = ((d.y0 + d.y1) / (isMobile ? 2.3 : 2)) * yMidRadiusModifier;

    const width = Math.abs(d.x1 - d.x0) * radius;
    const height =
      (Math.abs(d.y1 - d.y0) * (radius * (isMobile ? 1.2 : 1))) /
      heightRadiusModifier;

    return { width, height, xMid, yMid };
  };

  const labelTransform = (
    d: d3.HierarchyRectangularNode<ZoomableSunburstData>
  ) => {
    const { xMid, yMid } = calculateDimensions(d);

    const angle = (xMid * 180) / Math.PI;
    const translate = yMid * radius;
    const rotate = angle < 180 ? 0 : 180;

    return `rotate(${angle - 90}) translate(${translate},0) rotate(${rotate})`;
  };

  const labelText = (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) => {
    const { height } = calculateDimensions((d as any).current);

    return getTruncatedText(d.data.name, height);
  };

  const arcVisible = (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) => {
    return d.y1 <= (isMobile ? 2 : 3) && d.y0 >= 1 && d.x1 > d.x0;
  };

  const labelVisible = (
    d: d3.HierarchyRectangularNode<ZoomableSunburstData>
  ) => {
    return (
      d.y1 <= (isMobile ? 2 : 3) &&
      d.y0 >= 1 &&
      (d.y1 - d.y0) * (d.x1 - d.x0) > 0.04
    );
  };

  const hierarchy = d3
    .hierarchy<ZoomableSunburstData>(data)
    .sum((d) => d.value || 0)
    .sort((a, b) => (b.value || 0) - (a.value || 0));

  const root = d3
    .partition<ZoomableSunburstData>()
    .size([2 * Math.PI, hierarchy.height + 1])(hierarchy);
  // eslint-disable-next-line no-param-reassign
  root.each((d) => {
    // eslint-disable-next-line no-param-reassign
    (d as any).current = d;
  });

  // Create the arc generator.
  const arc = d3
    .arc<d3.HierarchyRectangularNode<ZoomableSunburstData>>()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius((d) => {
      const radiusModifier = Math.max(innerRadiusModifierScale(d.y0), 0);

      return d.y0 * (radius * (isMobile ? 0.5 : 1)) * radiusModifier;
    })
    .outerRadius((d) => {
      const radiusModifier = Math.max(outerRadiusModifierScale(d.y0), 0);

      return Math.max(d.y0 * radius, d.y1 * radius - 1) * radiusModifier;
    });

  const parent = svgSelection
    .append('circle')
    .datum(root)
    .attr('r', radius)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    .on('click', clicked);

  const textGroup = svgSelection
    .append('g')
    .style('fill-opacity', 0)
    .style('display', 'none')
    .style('font', '12px sans-serif')
    .style('transform', isMobile ? 'scale(0.8)' : 'none')
    .style('pointer-events', 'none')
    .attr('text-anchor', 'middle');

  // Append the text element
  const textElement = textGroup
    .append('text')
    .style('fill', 'rgba(54, 54, 54, 1)')
    .attr('y', 0) // Adjust starting y position
    .attr('x', 0); // Center the text if necessary

  // Add tspan with text
  textElement
    .append('tspan')
    .attr('x', 6) // Adjust x to allow room for the SVG
    .attr('dy', '0em')
    .text(translations.backTo);

  // Append the SVG image for the arrow (assumes you have a valid SVG URL or path)
  textGroup
    .append('image')
    .attr('xlink:href', '/assets/common/back.svg') // Path to your SVG icon
    .attr('x', preferredLanguage.includes('de') ? -42 : -32) // Positioning the SVG relative to text
    .attr('y', -13)
    .attr('width', 16)
    .attr('height', 16);

  // Add second line of text
  textElement
    .append('tspan')
    .attr('x', 0)
    .attr('dy', '1.2em')
    .text(translations.prevLevel);

  function clicked(
    _event: MouseEvent,
    p: d3.HierarchyRectangularNode<ZoomableSunburstData>
  ) {
    textGroup
      .transition()
      .duration(400)
      .style('fill-opacity', p.depth === 0 ? 0 : 1)
      .style('display', p.depth === 0 ? 'none' : 'inline');

    selectItem(
      p.depth === 0
        ? null
        : {
            name: p.data.name,
            code: p.data.code || ''
          }
    );

    if (!p.data.children?.length) {
      return;
    }

    parent.datum(p.parent || root);

    root.each(
      // eslint-disable-next-line no-return-assign
      (d) =>
        // eslint-disable-next-line no-param-reassign
        ((d as any).target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth)
        })
    );

    const t = svgSelection.transition().duration(500);

    // Transition the data on all arcs, even the ones that aren't visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.
    // @ts-expect-error call-before-assign
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    path
      .transition(t as d3.Transition<BaseType, any, any, any>)
      .tween('data', (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) => {
        const i = d3.interpolate((d as any).current, (d as any).target);
        // eslint-disable-next-line no-return-assign, no-param-reassign
        return (t: any) => ((d as any).current = i(t));
      })
      // @ts-expect-error error type
      // eslint-disable-next-line func-names
      .filter(function (d) {
        // @ts-expect-error error type
        return +this.getAttribute('fill-opacity') || arcVisible(d.target);
      })
      .attr('fill-opacity', (d) =>
        // eslint-disable-next-line no-nested-ternary
        arcVisible((d as any).target)
          ? // eslint-disable-next-line no-nested-ternary
            d.children
            ? d.depth === 1
              ? 1
              : 0.7
            : 0.4
          : 0
      )
      .attr('pointer-events', (d) =>
        arcVisible((d as any).target) ? 'auto' : 'none'
      )
      .attrTween('d', (d) => () => arc((d as any).current))

      // eslint-disable-next-line func-names
      .on('end', function () {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        label
          // eslint-disable-next-line func-names
          .filter(function (d) {
            return arcVisible((d as any).target);
          })
          .attr('transform', (d) => labelTransform((d as any).current))
          .text((d) => labelText(d));
      });

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    label
      // @ts-expect-error error type
      // eslint-disable-next-line func-names
      .filter(function (d) {
        // @ts-expect-error error type
        return +this.getAttribute('fill-opacity') || labelVisible(d.target);
      })
      .transition(t as d3.Transition<BaseType, any, any, any>)
      .attr('fill-opacity', (d) => +labelVisible((d as any).target))
      .attrTween('transform', (d) => () => labelTransform((d as any).current))
      .textTween((d) => () => labelText(d));
  }

  // Append the arcs.
  const path = svgSelection
    .append('g')
    .selectAll('path')
    .data(root.descendants().slice(1))
    .join('path')
    .attr('fill', (d) => {
      // eslint-disable-next-line no-param-reassign
      while (d.depth > 1 && d.parent) d = d.parent;
      return color(d.data.name);
    })
    .attr('fill-opacity', (d) => {
      // eslint-disable-next-line no-nested-ternary
      return arcVisible(d) ? (d.children ? (d.depth === 1 ? 1 : 0.7) : 0.4) : 0;
    })
    .attr('pointer-events', (d) => (arcVisible(d) ? 'auto' : 'none'))
    .attr('d', (d) => arc(d));

  // Make them clickable if they have children.
  path
    // .filter((d) => !!d.children)
    .style('cursor', 'pointer')
    .on('click', clicked);

  path
    .append('title')
    .text((d) => d.data.name)
    .style('fill', 'red');

  const label = svgSelection
    .append('g')
    .attr('pointer-events', 'none')
    .attr('text-anchor', 'middle')
    .style('user-select', 'none')
    .selectAll('text')
    .data(root.descendants().slice(1))
    .join('text')
    .attr('dy', '0.35em')
    .attr('fill-opacity', (d) => +labelVisible(d))
    .attr('transform', (d) => labelTransform(d))
    .style('font-size', isMobile ? '12px' : '10px')
    .text((d) => labelText(d));
};

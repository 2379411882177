import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Modal, SubTitle } from 'components';
import { Routes } from 'enums';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const DashboardVariationLimitModal: FC<Props> = ({
  isOpen,
  onClose
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onNavigateToResearchPage = () => {
    navigate(Routes.NewChat);
  };

  return (
    <Modal
      isOpen={isOpen}
      className={styles.container}
      titleClassName={styles.title}
      title={t('Page.Dashboard.DashboardVariationLimitModal.Title')}
    >
      <SubTitle className={styles.subtitle}>
        {t('Page.Dashboard.DashboardVariationLimitModal.Subtitle')}
      </SubTitle>

      <div className={styles.buttons}>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="contained"
          className={styles.button}
          onClick={onNavigateToResearchPage}
        >
          {t('Page.Dashboard.DashboardVariationLimitModal.Confirm')}
        </Button>
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          onClick={onClose}
          className={styles.button}
        >
          {t('Page.Dashboard.DashboardVariationLimitModal.Cancel')}
        </Button>
      </div>
    </Modal>
  );
};

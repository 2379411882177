import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Card } from 'components';
import { Routes } from 'enums';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { Stepper } from '../Stepper';

import styles from './styles.module.scss';

const steps = [
  {
    title: 'Page.Pricing.Report.Free.Steps.First.Title',
    description: 'Page.Pricing.Report.Free.Steps.First.Description'
  },
  {
    title: 'Page.Pricing.Report.Free.Steps.Second.Title',
    description: 'Page.Pricing.Report.Free.Steps.Second.Description'
  },
  {
    title: 'Page.Pricing.Report.Free.Steps.Third.Title',
    description: 'Page.Pricing.Report.Free.Steps.Third.Description'
  }
];

export const FreeReportCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRequestFreeSnippet = () => {
    mixpanel?.track(MixpanelEvents.PricingPageRequestFreeSnippet);

    navigate(Routes.NewChat);
  };

  return (
    <Card className={cn(styles.card)}>
      <div>
        <div className={styles['header-container']}>
          <h3 className={styles.title}>
            {t('Page.Pricing.Report.Free.Title')}
          </h3>
        </div>
        <div className={styles['description-container']}>
          <p className={styles.description}>
            {t('Page.Pricing.Report.Free.Price')}
          </p>
          <p className={styles.subdescription}>
            {t('Page.Pricing.Report.Free.Subtitle')}
          </p>
        </div>
      </div>

      <div className={styles.stepper}>
        <Stepper steps={steps} />
      </div>

      <p className={styles.subdescription}>
        {t('Page.Pricing.Report.Free.UnlockSubtitle')}
      </p>

      <Button
        size="large"
        color="primary"
        variant="outlined"
        className={styles.button}
        onClick={handleRequestFreeSnippet}
      >
        {t('Page.Pricing.Report.Free.GetFreeSnippet')}
      </Button>
    </Card>
  );
};

import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { BackdropOverlay, FloatingFilter } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardFilter } from 'entities/DashboardFIlter.entity';
import { InsightsType, queryKeys, Routes } from 'enums';
import { useIsExternalUserDashboard } from 'hooks';
import {
  useCreatedNestedDashboard,
  useUserCoins,
  useUserInfo
} from 'hooks/api';

import { DashboardVariationLimitModal } from '../DashboardVariationLimitModal';
import { findDuplicatedDashboardFilters } from './helpers';
import { NoCoinsModal } from './NoCoinsModal';
import { RemoveFilterModal } from './RemoveFilterModal';

interface Props {
  className?: string;
  data: DashboardData['filters'];
}

export const Filter: FC<Props> = ({ data, className }) => {
  const navigate = useNavigate();
  const { chatId, dashboardId, messageId } = useParams();
  const [isVariationLimitModalOpen, setVariationLimitModalOpen] =
    useState(false);
  const [isRemoveFilterModalOpen, setRemoveFilterModalOpen] = useState(false);
  const [isNoCoinsModalOpen, setNoCoinsModalOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState<DashboardFilter[]>([]);

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const isExternalUserDashboard = useIsExternalUserDashboard();

  const dashboardHistoryWidget = dashboardData?.widgets?.find(
    ({ type }) => type === InsightsType.DashboardHistory
  );

  const openRemoveFilterModal = useCallback(
    () => setRemoveFilterModalOpen(true),
    []
  );

  const closeRemoveFilterModal = useCallback(() => {
    setActiveFilter([]);
    setRemoveFilterModalOpen(false);
  }, []);

  const openNoCoinsModal = useCallback(() => setNoCoinsModalOpen(true), []);

  const closeNoCoinsModal = useCallback(() => setNoCoinsModalOpen(false), []);

  const openVariationLimitModal = useCallback(
    () => setVariationLimitModalOpen(true),
    []
  );

  const closeVariationLimitModal = useCallback(
    () => setVariationLimitModalOpen(false),
    []
  );

  const { mutate: createNestedDashboard, isPending } =
    useCreatedNestedDashboard({
      showVariationLimitModal: openVariationLimitModal,
      showNoCoinsModal: openNoCoinsModal
    });

  const hasCoins = userInfo?.isPremiumUser ? true : userCoins?.count;

  const onRemoveFilter = (row: { typeName: string; name: string | number }) => {
    const newFilters = data?.filter(
      ({ typeName }) => typeName !== row.typeName
    );

    if (dashboardHistoryWidget) {
      const isDashboardAlreadyGenerated = !!findDuplicatedDashboardFilters(
        dashboardHistoryWidget.params?.data || [],
        newFilters || []
      );

      if (isDashboardAlreadyGenerated) {
        createNestedDashboard({
          dashboardId: dashboardId || '',
          chatId: chatId || '',
          originDashboardId: dashboardData?.originDashboardId,
          data: newFilters || []
        });
      } else if (!hasCoins) {
        openNoCoinsModal();
      } else if (!isDashboardAlreadyGenerated) {
        setActiveFilter(newFilters || []);
        openRemoveFilterModal();
      }
    }
  };

  const createNewDashboard = () => {
    createNestedDashboard({
      dashboardId: dashboardId || '',
      chatId: chatId || '',
      originDashboardId: dashboardData?.originDashboardId,
      data: activeFilter
    });

    setActiveFilter([]);
  };

  const handleNoCoinsModalConfirm = () => {
    navigate(Routes.SubscriptionPlans, {
      state: { scrollToTop: true }
    });
  };

  const mergedFiltersByType = useMemo(() => {
    const map = new Map<string, string[]>();

    data?.forEach((item) => {
      if (map.has(item.typeName)) {
        map.get(item.typeName)!.push(item.name);
      } else {
        map.set(item.typeName, [item.name]);
      }
    });

    return Array.from(map, ([typeName, names]) => ({
      name: names.join(', '),
      typeName
    }));
  }, [data]);

  return (
    <FloatingFilter
      className={className}
      data={mergedFiltersByType}
      onRemoveFilter={onRemoveFilter}
      disableRemove={!!userInfo?.deactivatedAt || isExternalUserDashboard}
    >
      {isPending && <BackdropOverlay isPending={isPending} />}
      <DashboardVariationLimitModal
        onClose={closeVariationLimitModal}
        isOpen={isVariationLimitModalOpen}
      />
      <NoCoinsModal
        isOpen={isNoCoinsModalOpen}
        onClose={closeNoCoinsModal}
        onConfirm={handleNoCoinsModalConfirm}
      />
      <RemoveFilterModal
        onConfirm={createNewDashboard}
        onClose={closeRemoveFilterModal}
        isOpen={isRemoveFilterModalOpen}
      />
    </FloatingFilter>
  );
};

import { useQuery } from '@tanstack/react-query';
import { checkFreeSnippetAvailability } from 'actions/reportsActions';
import { queryKeys } from 'enums';

export const useIsFreeSnippetAvailable = () =>
  useQuery({
    queryKey: queryKeys.freeSnippetAvailability,
    queryFn: () => checkFreeSnippetAvailability(),
    refetchOnWindowFocus: false
  });

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { fetchChats } from 'actions/chatsActions';
import { Chat } from 'entities/Chat.entity';
import { queryKeys } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';
import { FetchChatsFilters } from 'services/API/Chats/ChatsApi';

const defaultChatsLimit = 10;
const defaultChatsOffset = 0;

export const usePaginatedChats = (filters?: FetchChatsFilters) => {
  const { isAuthenticated } = useAuthContext();

  return useInfiniteQuery<Chat[]>({
    enabled: isAuthenticated,
    queryKey: queryKeys.paginatedChats(filters),
    queryFn: ({ pageParam }) => {
      const offset = pageParam as number;
      const limit = filters?.limit || defaultChatsLimit;

      return fetchChats({
        ...filters,
        limit,
        offset
      });
    },
    initialPageParam: filters?.offset || defaultChatsOffset,
    getNextPageParam: (lastPage, pages) => {
      const limit = filters?.limit || defaultChatsLimit;
      const nextOffset = limit * pages.length;

      if (lastPage?.length < limit) return null;

      return nextOffset;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: 1,
    placeholderData: keepPreviousData
  });
};

import { FC, useState } from 'react';
import { ScrollToAnchor } from 'components';
import { PricingTabs } from 'enums/PricingTabs.enum';

import { CRMBooster } from './CRMBooster';
import { Report } from './Report';
import { SubscriptionPlan } from './SubscriptionPlan';
import { Tabs } from './Tabs';

import styles from './styles.module.scss';

export const Pricing: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(PricingTabs.Plans);

  const renderTabContent = () => {
    switch (activeTab) {
      case PricingTabs.Plans:
        return <SubscriptionPlan />;
      case PricingTabs.Report:
        return <Report />;
      case PricingTabs.CRMBooster:
        return <CRMBooster />;
      default:
        return <SubscriptionPlan />;
    }
  };

  return (
    <div className={styles.container}>
      <Tabs activeTab={activeTab} changeTab={setActiveTab} />
      <ScrollToAnchor />
      {renderTabContent()}
    </div>
  );
};

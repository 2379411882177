import { FC, MouseEvent, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchNestedDashboards } from 'actions/dashboardActions';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { EditableTitle } from 'components/Sidebar/EditableTitle';
import { DashboardData } from 'entities/Dashboard.entity';
import { InsightsType, queryKeys } from 'enums';
import { useUpdateDashboardTitle } from 'hooks/api/useUpdateDashboardTitle';
import { queryClient } from 'index';

import styles from './styles.module.scss';

interface Props {
  id: string;
  title: string;
  children: ReactNode;
  inputClassName?: string;
}

export const EditableRow: FC<Props> = ({
  id,
  title,
  children,
  inputClassName
}) => {
  const { t } = useTranslation();
  const { dashboardId } = useParams();

  const [isEditable, setEditable] = useState<boolean>(false);

  const toggleEditable = useCallback((e?: MouseEvent<HTMLSpanElement>) => {
    e?.stopPropagation();
    setEditable((value) => !value);
  }, []);

  const updateDashboardData = (updatedTitle: string) => {
    if (!dashboardId) {
      return;
    }

    const prevDashboardData: DashboardData | undefined =
      queryClient.getQueryData(queryKeys.dashboardDetails(dashboardId));

    if (!prevDashboardData) return;

    const updatedWidgets = prevDashboardData.widgets?.map((widget) => {
      if (widget.type !== InsightsType.DashboardHistory) {
        return widget;
      }

      const updatedWidgetData = widget?.params?.data?.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          title: updatedTitle
        };
      });

      return {
        ...widget,
        params: {
          ...widget.params,
          data: updatedWidgetData
        }
      };
    });

    if (updatedWidgets?.length) {
      queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), {
        ...prevDashboardData,
        widgets: updatedWidgets
      });
    }
  };

  const invalidateDashboardData = () => {
    if (dashboardId) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.nestedDashboards(dashboardId)
      });
      queryClient.fetchQuery({
        queryKey: queryKeys.nestedDashboards(dashboardId),
        queryFn: () => fetchNestedDashboards(dashboardId)
      });
    }
  };

  const onUpdateDashboardTitle = (text: string) => {
    invalidateDashboardData();
    updateDashboardData(text);
    toggleEditable();
  };

  const { mutate: updateMutation } = useUpdateDashboardTitle(
    id || '',
    onUpdateDashboardTitle
  );

  const onSubmit = (value: string) => {
    if (value === title) {
      toggleEditable();
    } else {
      updateMutation({ title: value });
    }
  };

  return (
    <div className={styles.container}>
      {isEditable ? (
        <EditableTitle
          value={title}
          onSubmit={onSubmit}
          className={styles.input}
          inputClassName={inputClassName}
          toggleEditable={toggleEditable}
          label={t('Form.Placeholder.DashboardName')}
          placeholder={t('Form.Placeholder.DashboardName')}
        />
      ) : (
        <>
          {children}
          <span className={styles.actions} onClick={toggleEditable}>
            <EditIcon className={styles['action-icon']} />
          </span>
        </>
      )}
    </div>
  );
};

import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as EmptySearchIcon } from 'assets/EmptySearchIcon.svg';
import { SearchFilterInput } from 'components';
import { Routes } from 'enums';
import { usePaginatedReports } from 'hooks/api';

import { ReportCard } from './ReportCard';
import { RequestReportCard } from './RequestReportCard';

import styles from './styles.module.scss';

export const Report: FC = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const navigate = useNavigate();
  const filters = { title: searchQuery };
  const { data, isFetched, hasNextPage, fetchNextPage } =
    usePaginatedReports(filters);

  const handleSearchButtonClick = (searchQuery: string | null) => {
    setSearchQuery(searchQuery);
  };

  const renderEmptyState = () => (
    <div className={styles['empty-state']}>
      <EmptySearchIcon />
      <h3 className={styles['empty-state-title']}>
        {t('Page.Report.EmptyState.Title')}
      </h3>
      <span className={styles['empty-state-description']}>
        {t('Page.Report.EmptyState.Description')}
      </span>
    </div>
  );

  useEffect(() => {
    if (isFetched && !data && !searchQuery) navigate(Routes.NewChat);
  }, [data, isFetched, navigate, searchQuery]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <SearchFilterInput
          placeholder={t('Page.Report.InputPlaceholder')}
          className={styles.search}
          onSubmit={handleSearchButtonClick}
        />
        {!data?.pages?.[0]?.length && searchQuery ? (
          renderEmptyState()
        ) : (
          <div className={styles.cards}>
            <RequestReportCard />
            {data?.pages.map((page) =>
              page.map((report) => (
                <Fragment key={report.title}>
                  <ReportCard data={report} loading={!report.filename} />
                </Fragment>
              ))
            )}
          </div>
        )}

        {hasNextPage && (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            className={styles['show-more']}
            onClick={() => fetchNextPage()}
          >
            {t('Page.Report.ShowMore')}
          </Button>
        )}
      </div>
    </div>
  );
};

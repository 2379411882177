import { FC, ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SubscriptionCycles } from 'enums';
import { Questions } from 'views/Pricing/Questions';

import styles from './styles.module.scss';

interface Props {
  title: string;
  subtitle: string;
  children: ReactNode;
  questions: Array<{
    title: string;
    description: string;
    button?: string;
  }>;
}

export const PricingWrapper: FC<Props> = ({
  title,
  subtitle,
  children,
  questions
}) => {
  const [searchParams] = useSearchParams();

  const [billingCycle, setBillingCycle] = useState<SubscriptionCycles>(
    SubscriptionCycles.Monthly
  );

  const selectedBillingCycle = searchParams.get('billing-cycle');

  useEffect(() => {
    if (selectedBillingCycle && selectedBillingCycle !== billingCycle) {
      setBillingCycle(selectedBillingCycle as SubscriptionCycles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1 className={styles.heading}>{title}</h1>
      <p className={styles.description}>{subtitle}</p>
      {children}
      <Questions questions={questions} />
    </>
  );
};

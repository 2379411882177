import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { CommonTooltip, Tooltip } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { InsightsType, queryKeys, Routes } from 'enums';
import { useExternalDashboardLink, useIsExternalUserDashboard } from 'hooks';
import {
  useCreatedNestedDashboard,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import type { SelectedFilterItem } from 'types';
import { DashboardFilterModal } from 'views/DashboardV2/DashboardFilterModal';

import { BackdropOverlay } from '../../../components/BackdropOverlay';
import { DashboardVariationLimitModal } from '../DashboardVariationLimitModal';

import styles from './styles.module.scss';

interface Props {
  tooltip: string;
  className?: string;
  filterClassName?: string;
  widgetType: InsightsType;
  type: string;
  selectedItem: SelectedFilterItem;
  notes: {
    active: string;
    inactive: string;
  };
}

export const WidgetFilter: FC<Props> = ({
  tooltip,
  type,
  notes,
  widgetType,
  filterClassName,
  selectedItem,
  className
}) => {
  const navigate = useNavigate();
  const { data: userInfo } = useUserInfo();
  const { t } = useTranslation();
  const { data: userCoins } = useUserCoins();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { chatId, dashboardId, messageId } = useParams();
  const [isApplyFilterModalOpen, setApplyFilterModalOpen] = useState(false);
  const [isVariationLimitModalOpen, setVariationLimitModalOpen] =
    useState(false);

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const externalDashboardLink = useExternalDashboardLink();

  const hasCoins = userInfo?.isPremiumUser ? true : userCoins?.count;

  const openVariationLimitModal = useCallback(
    () => setVariationLimitModalOpen(true),
    []
  );

  const closeVariationLimitModal = useCallback(
    () => setVariationLimitModalOpen(false),
    []
  );

  const { mutate: createNestedDashboard, isPending } =
    useCreatedNestedDashboard({
      showVariationLimitModal: openVariationLimitModal
    });

  const sendMixpanelEvent = (mixpanelEvent: MixpanelEvents) => {
    mixpanel?.track(mixpanelEvent, {
      'Dashboard ID': dashboardId,
      'Widget type': widgetType,
      'Filter value': selectedItem?.name,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': externalDashboardLink,
      'Default Industries': dashboardData?.rootIndustries?.join(', ') || '-',
      'Nested Industries': dashboardData?.nestedIndustries?.join(', ') || '-'
    });
  };

  const onApplyFilter = () => {
    createNestedDashboard({
      dashboardId: dashboardId || '',
      chatId: chatId || '',
      originDashboardId: dashboardData?.originDashboardId,
      data: [
        ...(dashboardData?.filters || []),
        {
          type: widgetType,
          value: selectedItem?.code || '',
          name: selectedItem?.name || '',
          typeName: type
        }
      ]
    });
  };

  const handleApplyFilterModalClose = () => {
    if (!hasCoins) {
      sendMixpanelEvent(MixpanelEvents.CancelGoPricingClick);
    } else {
      sendMixpanelEvent(MixpanelEvents.CancelFilterClick);
    }

    setApplyFilterModalOpen(false);
  };
  const handleApplyFilterModalConfirm = () => {
    if (!hasCoins) {
      navigate(Routes.SubscriptionPlans, {
        state: { scrollToTop: true }
      });

      sendMixpanelEvent(MixpanelEvents.GoPricingClick);

      return;
    }

    sendMixpanelEvent(MixpanelEvents.ConfirmFilterClick);

    setApplyFilterModalOpen(false);
    onApplyFilter();
  };

  const handleFilterButtonClick = () => {
    mixpanel?.track(MixpanelEvents.FilterClick, {
      'Dashboard ID': dashboardId,
      'Widget type': widgetType,
      'Filter value': selectedItem?.name,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': externalDashboardLink,
      'Default Industries': dashboardData?.rootIndustries?.join(', ') || '-',
      'Nested Industries': dashboardData?.nestedIndustries?.join(', ') || '-'
    });

    if (!selectedItem) return null;

    if (userInfo?.isFreeUser) {
      setApplyFilterModalOpen(true);
    } else {
      onApplyFilter();
    }
  };

  return (
    <div className={cn(className, styles.container)}>
      {isPending && <BackdropOverlay isPending={isPending} />}
      <DashboardVariationLimitModal
        onClose={closeVariationLimitModal}
        isOpen={isVariationLimitModalOpen}
      />
      <DashboardFilterModal
        hasCoins={!!hasCoins}
        isOpen={isApplyFilterModalOpen}
        industry={selectedItem?.name || ''}
        onClose={handleApplyFilterModalClose}
        onConfirm={handleApplyFilterModalConfirm}
      />
      <div className={styles['filter-wrapper']}>
        <div className={cn(styles.filter, filterClassName)}>
          <CommonTooltip
            title={selectedItem?.name || tooltip}
            className={cn(
              styles.title,
              'overflowed-text',
              selectedItem && styles['has-value']
            )}
          />
        </div>
        <Tooltip
          disabled={!userInfo?.deactivatedAt}
          title={t('Common.DeactivatedAccountNote')}
        >
          <span>
            <Button
              size="small"
              color="primary"
              variant="contained"
              className={styles.button}
              onClick={handleFilterButtonClick}
              disabled={
                !selectedItem ||
                !!userInfo?.deactivatedAt ||
                isExternalUserDashboard
              }
            >
              {t('Common.ApplyFilter')}
            </Button>
          </span>
        </Tooltip>
      </div>
      <div className={styles.note}>
        <InfoIcon />

        {selectedItem ? notes.active : notes.inactive}
      </div>
    </div>
  );
};

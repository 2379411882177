import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { ChatStatement } from 'components/Chat/ChatStatement';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { InsightsType, MessageTypes, queryKeys } from 'enums';
import type { SelectedFilterItem } from 'types';

import { WidgetFilter } from '../WidgetFilter';
import { ZoomableTreemap } from './ZoomableTreemap';

import styles from './styles.module.scss';

interface Props {
  summary?: string;
  title?: string;
  className?: string;
  data?: DashboardParams;
  widgetType: InsightsType;
  isTextWidgetsProcessing?: boolean;
}

const WARNING_COLORS = {
  root: '#e74e00',
  start: '#ef6a00',
  end: '#fff3df'
};

const SUCCESS_COLORS = {
  root: '#007727',
  start: '#00983d',
  end: '#e1faec'
};

export const TreemapThemeColors = Object.freeze({
  [InsightsType.Treemap]: SUCCESS_COLORS,
  [InsightsType.HierarchyProducts]: WARNING_COLORS
});
export const ZoomableTreemapChart: FC<Props> = ({
  widgetType,
  data,
  title,
  summary,
  className,
  isTextWidgetsProcessing
}) => {
  const { dashboardId, messageId } = useParams();
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<SelectedFilterItem>(null);
  const selectItem = (data: SelectedFilterItem) => {
    setSelectedItem(data);
  };

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const chartData = data?.data?.[0];
  // @ts-expect-error type error
  const chartColors = TreemapThemeColors[widgetType];

  const showFilter = dashboardData?.revision && dashboardData.revision > 2;

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.heading}>
        <CommonTooltip
          className={cn(styles.title, 'overflowed-text-multiline')}
          title={title || t('Page.Dashboard.Treemap.Title')}
        />
        {showFilter && (
          <WidgetFilter
            widgetType={widgetType}
            type={data?.type || ''}
            selectedItem={selectedItem}
            className={styles['filter-container']}
            tooltip={t('Page.Dashboard.Treemap.ChooseFilter')}
            notes={{
              active: t('Page.Dashboard.Treemap.NoteActive', {
                machines: selectedItem?.name || '-'
              }),
              inactive: t('Page.Dashboard.Treemap.Note')
            }}
          />
        )}
      </div>
      {chartData ? (
        <div className={styles.treemap}>
          <ZoomableTreemap
            data={chartData}
            chartColors={chartColors}
            selectItem={selectItem}
          />
        </div>
      ) : (
        <WidgetEmptyState className={styles['empty-state']} />
      )}
      {(isTextWidgetsProcessing || summary) && (
        <div className={styles.summary}>
          <ChatStatement
            showAvatar={false}
            maxLettersAmount={1200}
            isTextWidgetsProcessing={isTextWidgetsProcessing}
            data={{ type: MessageTypes.answer, statement: summary }}
          />
        </div>
      )}
    </div>
  );
};

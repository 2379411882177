/* eslint-disable no-restricted-syntax */
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { sendMessage } from 'actions/chatsActions';
import { fetchCoins } from 'actions/commonActions';
import { ApiError } from 'entities/ApiError.entity';
import { Message } from 'entities/Message.entity';
import { MessageTypes } from 'enums/MessageTypes.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useAnswerFromStream } from 'hooks/api/useAnswerFromStream';
import { queryClient } from 'index';
import mixpanel, { MixpanelEvents } from 'mixpanel';

export const useCreateMessage = ({
  abortController,
  setSearchValue,
  setIsLoadingAnimation
}: {
  abortController: MutableRefObject<AbortController | null>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setIsLoadingAnimation?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { chatId } = useParams();
  const { t } = useTranslation();
  const { setAnswerStatementFromStream } = useAnswerFromStream();

  const removeStatusMessages = (currentChatId: string) => {
    queryClient.setQueryData(
      queryKeys.chatMessages(currentChatId || ''),
      (previousMessages: Message[]) =>
        (previousMessages || []).filter(
          (previousMessage) => previousMessage.type !== MessageTypes.status
        )
    );
  };

  const { mutate, isPending: isMessageSending } = useMutation<
    {
      id: string;
      messageId: string;
      statement: string;
      dashboardId: string;
      rootIndustries?: string[] | null;
    } | void,
    ApiError,
    { message: string; currentChatId: string; mixpanelEvent?: MixpanelEvents }
  >({
    mutationFn: ({ message, currentChatId }) => {
      if (setIsLoadingAnimation) setIsLoadingAnimation(true);

      const questionMessage: Message = {
        statement: message,
        type: MessageTypes.question
      };

      queryClient.setQueryData(
        queryKeys.chatMessages(currentChatId),
        (previousMessages: Message[]) => [
          questionMessage,
          ...(previousMessages || [])
        ]
      );

      if (abortController?.current) {
        abortController?.current?.abort();
      }

      // eslint-disable-next-line no-param-reassign
      abortController.current = new AbortController();

      return sendMessage(
        currentChatId!,
        message,
        abortController,
        setAnswerStatementFromStream(currentChatId)
      );
    },
    async onSuccess(successData, { currentChatId, mixpanelEvent, message }) {
      if (!successData) return;

      mixpanel?.track(mixpanelEvent as MixpanelEvents, {
        Text: message,
        'Chat ID': chatId,
        'Default Industries': successData?.rootIndustries?.join(', ') || '-'
      });

      await queryClient.invalidateQueries({ queryKey: queryKeys.userCoins });
      await queryClient.fetchQuery({
        queryKey: queryKeys.userCoins,
        queryFn: fetchCoins
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.nestedDashboards(successData.dashboardId || '')
      });

      queryClient.setQueryData(
        queryKeys.chatMessages(currentChatId || ''),
        (previousMessages: Message[]) =>
          (previousMessages || [])
            .filter(
              (previousMessage) => previousMessage.type !== MessageTypes.status
            )
            .map((previousMessage) => {
              if (previousMessage.id === successData.id) {
                return {
                  ...previousMessage,
                  ...successData,
                  isNotFull: false,
                  rootIndustries: successData.rootIndustries
                };
              }

              return previousMessage;
            })
      );
    },
    onError(error, { currentChatId }) {
      const setMessageData = setAnswerStatementFromStream(currentChatId);
      const id = `error-${Math.random()}`;

      if (error?.message === 'User is inactive') {
        setMessageData({
          id,
          messageId: id,
          statement: t('Page.Chat.DeactivatedAccountErrorDefaultAnswer'),
          type: MessageTypes.answer,
          activateAccountVisible: true
        });
      } else {
        setMessageData({
          id,
          messageId: id,
          statement: t('Page.Chat.ErrorDefaultAnswer'),
          type: MessageTypes.answer
        });
      }
    },
    onSettled(_, __, { currentChatId }) {
      removeStatusMessages(currentChatId);
      setSearchValue('');
      if (setIsLoadingAnimation) setIsLoadingAnimation(false);
    }
  });

  return {
    mutate,
    isPending: isMessageSending,
    isMessageSending
  };
};

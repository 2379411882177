import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface Props {
  steps: {
    title: string;
    description: string;
  }[];
}

export const Stepper: FC<Props> = ({ steps }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.stepper}>
      {steps.map(({ title, description }, index) => (
        <div key={title} className={styles.step}>
          <div className={styles['step-content']}>
            <span className={styles['step-index']}>{`0${index + 1}`}</span>
            <h4 className={styles['step-title']}>{t(title)}</h4>
            <span className={styles['step-description']}>{t(description)}</span>
          </div>
          <div className={styles['step-border']} />
        </div>
      ))}
    </div>
  );
};

import { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Checkbox, FormHelperText, TextField } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/CheckIcon2.svg';
import { ReactComponent as UncheckIcon } from 'assets/uncheck-small.svg';
import cn from 'classnames';
import { useLandingUrl } from 'hooks';
import { validateRequired } from 'utils/helpers/validators';

import styles from './styles.module.scss';

export type CompleteUserInfoFormData = {
  lastName: string;
  company: string;
  isSubscribedToNewsletter: boolean;
  isAgreedOnTermsAndConditions?: boolean;
};

interface Props {
  isLoading: boolean;
  submitLabel: string;
  children?: ReactNode;
  onSubmit: (data: CompleteUserInfoFormData) => void;
}

export const CompleteUserInfoForm: FC<Props> = ({
  onSubmit,
  isLoading,
  submitLabel,
  children
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors, defaultValues }
  } = useFormContext<CompleteUserInfoFormData>();

  const landingUrl = useLandingUrl();

  const hasLastName = defaultValues?.lastName;

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {!hasLastName && (
        <>
          <div className={styles.label}>{t('Form.Label.LastName')}</div>
          <Controller
            name="lastName"
            rules={{
              validate: (value: CompleteUserInfoFormData['lastName']) => {
                const trimmedLastName = value.trim();

                if (!validateRequired(trimmedLastName)) {
                  return t('Form.Validation.LastName.Required');
                }

                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="lastName"
                size="medium"
                placeholder={t('Form.Placeholder.LastName')}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                className={styles['input-container']}
                inputProps={{
                  className: styles.input
                }}
              />
            )}
          />
        </>
      )}

      <div className={styles.label}>{t('Form.Label.CompanyName')}</div>
      <Controller
        name="company"
        rules={{
          validate: (value: CompleteUserInfoFormData['company']) => {
            const trimmedCompany = value.trim();

            if (!validateRequired(trimmedCompany)) {
              return t('Form.Validation.CompanyName.Required');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="company"
            size="medium"
            placeholder={t('Form.Placeholder.ProfileCompanyName')}
            error={!!errors.company}
            helperText={errors.company?.message}
            className={styles['input-container']}
            inputProps={{
              className: styles.input
            }}
          />
        )}
      />

      <div className={styles['checkbox-container']}>
        <Controller
          name="isAgreedOnTermsAndConditions"
          rules={{
            validate: (
              value: CompleteUserInfoFormData['isAgreedOnTermsAndConditions']
            ) => {
              if (!value) {
                return t(
                  'Form.Validation.IsAgreedOnTermsAndConditions.Required'
                );
              }

              return true;
            }
          }}
          render={({ field }) => (
            <div className={styles['checkbox-content']}>
              <Checkbox
                {...field}
                color="primary"
                id="isAgreedOnTermsAndConditions"
                icon={<UncheckIcon />}
                checkedIcon={<CheckIcon />}
                disableRipple
                classes={{
                  root: styles.checkbox
                }}
              />
              <div className={styles['checkbox-label']}>
                <Trans
                  i18nKey="Form.Label.IsAgreedOnTermsAndConditions"
                  components={{
                    termsAndConditions: (
                      <a
                        href={`${landingUrl}/terms-and-conditions`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Service
                      </a>
                    ),
                    privacyPolicy: (
                      <a
                        href={`${landingUrl}/privacy-policy`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    )
                  }}
                />
              </div>
            </div>
          )}
        />

        {!!errors.isAgreedOnTermsAndConditions && (
          <FormHelperText className={styles['checkbox-error']}>
            {errors.isAgreedOnTermsAndConditions?.message}
          </FormHelperText>
        )}
      </div>

      <div className={styles['checkbox-container']}>
        <Controller
          name="isSubscribedToNewsletter"
          render={({ field }) => (
            <div className={styles['checkbox-content']}>
              <Checkbox
                {...field}
                color="primary"
                id="isSubscribedToNewsletter"
                icon={<UncheckIcon />}
                checkedIcon={<CheckIcon />}
                disableRipple
                classes={{
                  root: styles.checkbox
                }}
              />
              <div
                className={cn(
                  styles['checkbox-label'],
                  styles['checkbox-label-secondary']
                )}
              >
                {t('Form.Label.IsSubscribedToNewsletter')}
              </div>
            </div>
          )}
        />
      </div>

      {children}

      <LoadingButton
        size="medium"
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        className={styles.submit}
      >
        {submitLabel}
      </LoadingButton>
    </form>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '@mui/material';

import styles from './styles.module.scss';

interface Props {
  text?: string;
  isPending: boolean;
}

export const BackdropOverlay: FC<Props> = ({ text, isPending }) => {
  const { t } = useTranslation();

  return (
    <Backdrop className={styles.overlay} open={isPending}>
      <div className={styles['overlay-content']}>
        <span className={styles.text}>{text || t('Common.NewDashboard')}</span>
        <div className={styles.spinner} />
      </div>
    </Backdrop>
  );
};

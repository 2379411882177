import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PricingWrapper } from '../PricingWrapper';
import { FreeReportCard } from './FreeReportCard';
import { FullReportCard } from './FullReportCard';

import styles from './styles.module.scss';

const questions = [
  {
    title: 'Page.Pricing.Report.Questions.List.First.Title',
    description: 'Page.Pricing.CRMBooster.Questions.List.First.Description'
  },
  {
    title: 'Page.Pricing.Report.Questions.List.Second.Title',
    description: 'Page.Pricing.CRMBooster.Questions.List.Second.Description'
  },
  {
    title: 'Page.Pricing.Report.Questions.List.Third.Title',
    description: 'Page.Pricing.CRMBooster.Questions.List.Third.Description'
  }
];

export const Report: FC = () => {
  const { t } = useTranslation();

  return (
    <PricingWrapper
      questions={questions}
      title={t('Page.Pricing.Report.Title')}
      subtitle={t('Page.Pricing.Report.Subtitle')}
    >
      <div className={styles.reports}>
        <FullReportCard />
        <FreeReportCard />
      </div>
    </PricingWrapper>
  );
};

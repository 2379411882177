import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';
import { ReactComponent as RegenerateIcon } from 'assets/regenerate.svg';
import { ReactComponent as ThumbDownIcon } from 'assets/thumbs-down.svg';
import { ReactComponent as ThumbUpIcon } from 'assets/thumbs-up.svg';
import cn from 'classnames';
import { Tooltip } from 'components/Tooltip';
import { FeedbackTypes, MessageTypes } from 'enums';
import { useUserCoins, useUserInfo } from 'hooks/api';

import styles from './styles.module.scss';

interface Props {
  messageId?: string;
  dashboardId?: string | null;
  messageType: MessageTypes;
  isLastAnswer?: boolean;
  isRateSuccess?: boolean;
  isRatePending?: boolean;
  hasSuggestions: boolean;
  isDashboardPaid?: boolean;
  isAnswerNotFull?: boolean;
  isMessageSending?: boolean;
  isAnswerHasError?: boolean;
  isRateButtonHidden?: boolean;
  isAnswerHasFeedback?: boolean | null;
  isActivateAccountVisible: boolean;
  isDashboardButtonHidden?: boolean;
  isRegenerateMessageSending?: boolean;
  onShowDashboard: () => void;
  onOpenFeedbackModal: (feedback: FeedbackTypes | null) => void;
  onRegenerateMessage: (messageId?: string) => () => void;
  onActivateAccountClick?: () => void;
  onGenerateReport?: () => void;
  onRequestCRMBooster?: () => void;
}

export const ChatMessageActions: FC<Props> = ({
  messageId,
  dashboardId,
  messageType,
  isLastAnswer,
  isRateSuccess,
  isRatePending,
  hasSuggestions,
  isDashboardPaid,
  isAnswerNotFull,
  isMessageSending,
  isAnswerHasError,
  isRateButtonHidden,
  isAnswerHasFeedback,
  isDashboardButtonHidden,
  isActivateAccountVisible,
  isRegenerateMessageSending,
  onShowDashboard,
  onOpenFeedbackModal,
  onRegenerateMessage,
  onRequestCRMBooster,
  onGenerateReport,
  onActivateAccountClick
}) => {
  const { t } = useTranslation();
  const { data: userCoins } = useUserCoins();
  const { data: userInfo } = useUserInfo();

  const isRateAvailable =
    !isRateSuccess && !isRatePending && !isAnswerHasFeedback;
  const isAnswerInProgress =
    (isMessageSending || isRegenerateMessageSending) && isLastAnswer;
  const hideRateButton =
    isAnswerInProgress || isRateButtonHidden || hasSuggestions;
  const isDataPresent =
    !!dashboardId &&
    !isDashboardButtonHidden &&
    !isAnswerInProgress &&
    !isAnswerNotFull;
  const isRestartGenerationButtonVisible =
    isLastAnswer &&
    !isMessageSending &&
    !isRegenerateMessageSending &&
    isAnswerNotFull;
  const disableDashboard =
    !isDashboardPaid &&
    (userInfo?.deactivatedAt || (userInfo?.isFreeUser && !userCoins?.count));
  const isGenerateReportButtonVisible =
    !isAnswerHasError && messageType === MessageTypes.answer;

  const actionsVisible =
    (isActivateAccountVisible && messageType === MessageTypes.answer) ||
    isDataPresent ||
    isRestartGenerationButtonVisible ||
    isGenerateReportButtonVisible;

  return (
    <div
      className={cn(styles.container, !actionsVisible && styles['end-aligned'])}
    >
      {actionsVisible && (
        <div className={styles.actions}>
          {isGenerateReportButtonVisible && (
            <Tooltip
              title={t('Common.DeactivatedAccountNote')}
              disabled={!userInfo?.deactivatedAt}
              className={styles.tooltip}
            >
              <span>
                <Button
                  size="medium"
                  color="primary"
                  variant="contained"
                  disabled={!!userInfo?.deactivatedAt}
                  className={cn(styles['action-button'], 'brilliance')}
                  onClick={onGenerateReport}
                >
                  {t('Page.Chat.GenerateReport')}
                </Button>
              </span>
            </Tooltip>
          )}

          {isActivateAccountVisible && (
            <Button
              size="medium"
              color="primary"
              variant="contained"
              disabled={!userInfo?.deactivatedAt}
              onClick={onActivateAccountClick}
              className={styles['action-button']}
            >
              {t('Page.Profile.Settings.Activate.Button')}
            </Button>
          )}

          {isDataPresent && (
            <>
              <Tooltip
                title={
                  !userCoins?.count
                    ? t('Common.FreeSubscriptionFullDashboardButtonTooltip')
                    : t('Common.UnlockCoinsModal.DeactivatedAccountTooltip')
                }
                disabled={!disableDashboard}
                className={styles.tooltip}
              >
                <span>
                  <Button
                    size="medium"
                    color="primary"
                    variant="outlined"
                    disabled={!!disableDashboard}
                    className={cn(styles['action-button'])}
                    onClick={onShowDashboard}
                  >
                    {t(
                      !isDashboardPaid && userInfo?.isFreeUser
                        ? 'Page.Chat.ForDashboard'
                        : 'Page.Chat.ForPaidDashboard'
                    )}
                  </Button>
                </span>
              </Tooltip>

              <Tooltip
                title={t('Common.DeactivatedAccountNote')}
                disabled={!userInfo?.deactivatedAt}
                className={styles.tooltip}
              >
                <span>
                  <Button
                    size="medium"
                    color="primary"
                    variant="outlined"
                    disabled={!!userInfo?.deactivatedAt}
                    className={cn(styles['action-button'])}
                    onClick={onRequestCRMBooster}
                  >
                    {t('Page.Chat.BuyData')}
                  </Button>
                </span>
              </Tooltip>
            </>
          )}
        </div>
      )}

      <div className={styles['additional-actions']}>
        {isRestartGenerationButtonVisible && (
          <div className={styles['regenerate-container']}>
            <span className={styles.label}>
              {t('Page.Chat.RestartGeneration')}
            </span>
            <IconButton edge="end" onClick={onRegenerateMessage(messageId)}>
              <RegenerateIcon className={styles.icon} />
            </IconButton>
          </div>
        )}

        {messageType === MessageTypes.answer &&
          !hideRateButton &&
          !isAnswerHasError && (
            <div className={styles['rate-container']}>
              <span className={styles.label}>{t('Page.Chat.RateAnswer')}</span>
              <Tooltip
                title={t('Common.FeedbackModal.DisabledTooltip')}
                disabled={!isAnswerHasFeedback}
                className={styles.tooltip}
              >
                <IconButton
                  edge="end"
                  className={cn(
                    styles['rate-container-button'],
                    !isRateAvailable && styles.disabled
                  )}
                  onClick={() => onOpenFeedbackModal(FeedbackTypes.NEGATIVE)}
                >
                  <ThumbDownIcon className={styles.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t('Common.FeedbackModal.DisabledTooltip')}
                disabled={!isAnswerHasFeedback}
                className={styles.tooltip}
              >
                <IconButton
                  edge="end"
                  className={cn(
                    styles['rate-container-button'],
                    !isRateAvailable && styles.disabled
                  )}
                  onClick={() => onOpenFeedbackModal(FeedbackTypes.POSITIVE)}
                >
                  <ThumbUpIcon className={styles.icon} />
                </IconButton>
              </Tooltip>
            </div>
          )}
      </div>
    </div>
  );
};

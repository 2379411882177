import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ReactComponent as BellIcon } from 'assets/BellIcon.svg';
import { TitleWithBackButton } from 'components/TitleWithBackButton';
import { queryKeys } from 'enums';
import { usePaginatedNotifications } from 'hooks/api';
import { queryClient } from 'index';

import { useReadFetchedNotifications } from './hooks/useReadFetchedNotifications';
import { NotificationItem } from './NotificationItem';

import styles from './styles.module.scss';

export const Notifications: FC = () => {
  const { t } = useTranslation();
  const { data, isFetching, hasNextPage, fetchNextPage } =
    usePaginatedNotifications();

  const notifications = useMemo(
    () => data?.pages.reduce((acc, items) => acc.concat(...items), []),
    [data]
  );

  useReadFetchedNotifications(notifications);

  useEffect(
    () => () => {
      queryClient.resetQueries({ queryKey: queryKeys.paginatedNotifications });
    },
    []
  );

  const hasNotifications = !!notifications?.length;
  const isFetchingInitially = isFetching && !hasNotifications;

  if (isFetchingInitially) return null;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <TitleWithBackButton title={t('Page.Notifications.Title')} />

          <div className={styles.list}>
            {hasNotifications ? (
              notifications.map((notification) => (
                <NotificationItem key={notification.id} {...notification} />
              ))
            ) : (
              <div className={styles['empty-state']}>
                <BellIcon className={styles['empty-state-icon']} />

                <h2 className={styles['empty-state-title']}>
                  {t('Page.Notifications.EmptyState.Title')}
                </h2>

                <span className={styles['empty-state-description']}>
                  {t('Page.Notifications.EmptyState.Description')}
                </span>
              </div>
            )}
          </div>

          {hasNextPage && (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              className={styles['show-more']}
              onClick={() => fetchNextPage()}
            >
              {t('Page.Notifications.ShowMore')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as ChatIcon } from 'assets/chat.svg';
import cn from 'classnames';
import { Tooltip } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import {
  useDashboardContext,
  useExternalDashboardLink,
  useIsExternalUserDashboard
} from 'hooks';
import { useUserInfo } from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { APPOINTMENT_CALENDAR_URL } from 'utils/constants';

import styles from './styles.module.scss';

export const RequestDemoCall = () => {
  const { t } = useTranslation();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { dashboardId } = useParams();
  const { data: userInfo } = useUserInfo();

  const externalDashboardLink = useExternalDashboardLink();
  const { expertWidgetRef } = useDashboardContext();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  const onClick = () => {
    mixpanel?.track(MixpanelEvents.ContactGieniClick, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': externalDashboardLink,
      'Default Industries': dashboardData?.rootIndustries?.join(', ') || '-',
      'Nested Industries': dashboardData?.nestedIndustries?.join(', ') || '-'
    });

    window.open(APPOINTMENT_CALENDAR_URL, '_blank');
  };

  return (
    <div className={styles.container} ref={expertWidgetRef}>
      <div className={styles.heading}>
        <h2 className={styles.title}>
          {t('Page.Dashboard.RequestDemoCall.Title')}
        </h2>
        <span className={styles.subtitle}>
          {t('Page.Dashboard.RequestDemoCall.Subtitle')}
        </span>
      </div>

      <Tooltip
        disabled={!userInfo?.deactivatedAt}
        title={t('Common.DeactivatedAccountNote')}
      >
        <span className="flex">
          <Button
            size="small"
            color="primary"
            onClick={onClick}
            variant="contained"
            startIcon={<ChatIcon />}
            className={cn('brilliance')}
            disabled={isExternalUserDashboard || !!userInfo?.deactivatedAt}
          >
            {t('Page.Dashboard.RequestDemoCall.Cta')}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { AuthModal, ChatInput } from 'components';
import { Message } from 'entities/Message.entity';
import { MessageTypes, queryKeys } from 'enums';
import { Routes } from 'enums/Routes';
import {
  useAuthContext,
  useGlobalShortcutsContext,
  useIndustryConversation
} from 'hooks';
import {
  useActivateAccount,
  useChatDetails,
  useCreateChat,
  useCreateMessage,
  useFetchDashboardData,
  useMessages,
  useRegenerateChatMessage,
  useUserInfo
} from 'hooks/api';
import { queryClient } from 'index';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { SessionStorage } from 'services/SessionStorage';
import { SAVED_USER_QUERY } from 'utils/constants';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import { AnnouncementBar } from './AnnouncementBar';
import { ChatContainer } from './ChatContainer';
import { useInvalidateChatQueries } from './hooks/useInvalidateChatQueries';
import { useModals } from './hooks/useModals';
import { Suggestions } from './Suggestions';
import { UpgradeNote } from './UpgradeNote';

import styles from './styles.module.scss';

export const Conversation: FC = () => {
  const { t } = useTranslation();
  const appLocation = useLocation();
  const { setChatId } = useGlobalShortcutsContext();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const previousChatId = useRef<string | undefined>();
  const { chatId, industry } = useParams();
  const { data: userInfo } = useUserInfo();
  const isPublicChat = !appLocation.pathname.includes('/chats/');
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: fetchDashboardData } = useFetchDashboardData();
  const { data: chatDetails } = useChatDetails(chatId);
  const invalidateChatQueries = useInvalidateChatQueries();
  const { isAuthModalOpen, openAuthModal, closeAuthModal } = useModals();

  const subject = chatDetails?.subject;

  const disableNewQuestions =
    !!userInfo?.deactivatedAt && appLocation.pathname === Routes.NewChat;

  const abortController = useRef<AbortController | null>(null);
  const [isChatHidden, setIsChatHidden] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoadingAnimation, setIsLoadingAnimation] = useState(false);
  const [extendedMessages, setExtendedMessages] = useState<Message[] | null>(
    null
  );

  const interruptMessage = () => {
    abortController.current?.abort();
  };

  const {
    mutate: sendMessageMutation,
    isPending,
    isMessageSending
  } = useCreateMessage({
    abortController,
    setSearchValue,
    setIsLoadingAnimation
  });
  const {
    mutate: regenerateMessageMutation,
    isPending: isRegenerateMessageSending,
    isRegenerateMessageRequestPending
  } = useRegenerateChatMessage({
    abortController
  });
  const { mutate: createChatMutation } = useCreateChat(
    chatId,
    setExtendedMessages,
    sendMessageMutation
  );
  const {
    data: messages,
    isLoading: isMessagesLoading,
    isFetchedAfterMount
  } = useMessages(chatId, isPending);

  const { mutate: handleActivateAccount } = useActivateAccount(() => {
    if (!chatId && (subject || industry)) {
      createChatMutation({ subject: subject || industry });
    }
  });

  useIndustryConversation({
    createChat: createChatMutation,
    setMessages: setExtendedMessages,
    isMessagesFetched: isFetchedAfterMount
  });

  useEffect(() => {
    const questionFromQuery = searchParams.get('question');
    const questionFromStorage =
      SessionStorage.getItem<string>(SAVED_USER_QUERY);

    setSearchValue(questionFromQuery || questionFromStorage || '');

    if (!isPublicChat) {
      if (questionFromStorage) {
        SessionStorage.removeItem(SAVED_USER_QUERY);
      }

      if (questionFromQuery) {
        searchParams.delete('question');

        setSearchParams(searchParams);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chatId) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.chatMessages(chatId || '')
      });

      setChatId(chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (
      !chatId ||
      (previousChatId?.current != null &&
        chatId &&
        chatId !== previousChatId?.current)
    ) {
      interruptMessage();
    }

    if (chatId) {
      previousChatId.current = chatId;
    }

    return () => {
      if (!chatId || chatId === 'new') {
        previousChatId.current = undefined;
      }
    };
  }, [chatId]);

  useEffect(() => {
    if (messages?.length && (isFetchedAfterMount || chatId)) {
      setExtendedMessages(messages);
    }

    return () => {
      setExtendedMessages(null);
    };
  }, [messages, chatId, isFetchedAfterMount]);

  useEffect(() => {
    if (chatId || industry || subject) {
      handleAnimationSwitch(true);
    } else {
      handleAnimationSwitch(false);
    }
  }, [chatId, industry, subject]);

  const askQuestion = (message: string, mixpanelEvent?: MixpanelEvents) => {
    if (!chatId) {
      createChatMutation({ message, mixpanelEvent });
    } else {
      sendMessageMutation({ message, currentChatId: chatId, mixpanelEvent });
    }
  };

  const regenerateMessage = (messageId: string) => {
    if (chatId) {
      regenerateMessageMutation({ messageId, currentChatId: chatId });
    }
  };

  const isLoading =
    isPending || isLoadingAnimation || isRegenerateMessageRequestPending;

  const handleSubmit = () => {
    if (!isAuthenticated) {
      openAuthModal(searchValue);
      return;
    }

    if (searchValue && !isLoading) {
      askQuestion(searchValue, MixpanelEvents.QuestionAsked);
    }
  };

  const handleSuggestionClick = (text: string) => {
    if (!isAuthenticated) {
      openAuthModal(text);
      return;
    }

    setSearchValue(text);
    askQuestion(text, MixpanelEvents.SuggestionClick);
  };

  const handleDashboardClick = ({
    dashboardId,
    isPaid,
    rootIndustries
  }: {
    dashboardId: string;
    isPaid: boolean;
    rootIndustries?: string[] | null;
  }) => {
    fetchDashboardData({
      dashboardId,
      onSuccess: () => {
        invalidateChatQueries(dashboardId);

        if (!isPaid) {
          mixpanel?.track(MixpanelEvents.DashboardCreated, {
            'Dashboard ID': dashboardId,
            'User plan': userInfo?.subscriptionPlan,
            'Default Industries': rootIndustries?.join(', ') || '-',
            // eslint-disable-next-line no-restricted-globals
            'Dashboard link': `${location.origin}/admin/chats/${chatId}/dashboards/${dashboardId}`
          });
        }

        handleAnimationSwitch(false);
        setIsLoadingAnimation(true);
        setIsChatHidden(true);
        setSearchValue(t('Page.Chat.LoadingDashboard'));

        setTimeout(() => {
          setSearchValue('');
          setIsLoadingAnimation(false);

          const link = Routes.Dashboard.replace(
            ':dashboardId',
            dashboardId || ''
          ).replace(':chatId', chatId || '');

          navigate(link);
        }, 2500);
      }
    });
  };

  const lastAnswer = messages?.find(
    (message, index) => index === 0 && message.type === MessageTypes.answer
  );

  const stopMessage = () => {
    if (!chatId || !lastAnswer) {
      return;
    }

    interruptMessage();

    const updatedMessages = messages?.map((message) => {
      if (message.messageId === lastAnswer.messageId) {
        return {
          ...message,
          isNotFull: true
        };
      }
      return message;
    });

    if (updatedMessages?.length) {
      queryClient.setQueryData(queryKeys.chatMessages(chatId), updatedMessages);
    }
  };

  const renderUpgradeNote = () =>
    extendedMessages?.length &&
    !isChatHidden &&
    userInfo?.isFreeUser &&
    !userInfo?.deactivatedAt &&
    !!chatId && <UpgradeNote />;

  const isAnnouncementBarActive =
    !!chatId && !isChatHidden && !!extendedMessages?.length;

  return (
    <div className={styles.container}>
      {!isMessagesLoading && (
        <div className={styles.content}>
          <div className={styles.conversation}>
            {!messages?.length && !industry && !subject && (
              <h1>{t('Page.Chat.Title')}</h1>
            )}

            <AnnouncementBar isActive={isAnnouncementBarActive} />

            <Suggestions
              disabled={disableNewQuestions}
              isVisible={
                !industry && !subject && !messages?.length && !isLoading
              }
              onSuggestionClick={handleSuggestionClick}
            />

            <ChatContainer
              isChatHidden={isChatHidden}
              hasMessages={!!messages?.length}
              extendedMessages={extendedMessages}
              isMessageSending={isMessageSending}
              regenerateMessage={regenerateMessage}
              isLoading={isPending || isLoadingAnimation}
              handleDashboardClick={handleDashboardClick}
              handleSuggestionClick={handleSuggestionClick}
              isRegenerateMessageSending={isRegenerateMessageSending}
              handleActivateAccount={handleActivateAccount}
            />

            <div className={styles.input}>
              {renderUpgradeNote()}

              <ChatInput
                value={searchValue}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                setValue={setSearchValue}
                onStopMessage={stopMessage}
                isLastAnswer={!!lastAnswer}
                disabled={disableNewQuestions}
              />
            </div>
          </div>
        </div>
      )}
      <AuthModal isOpen={isAuthModalOpen} onClose={closeAuthModal} />
    </div>
  );
};

import { FC, useRef, useState } from 'react';
import { WidgetEmptyState } from 'components';
import { Map } from 'components/Map';
import { MapData } from 'components/Map/Map';
import type { SelectedFilterItem } from 'types';

import { MapHeader } from './MapHeader';

import styles from './styles.module.scss';

interface Props {
  data: MapData;
  title?: string;
  willCaptureImage?: boolean;
}

export const MapContainer: FC<Props> = ({ title, data, willCaptureImage }) => {
  const { params } = data;

  const headerRef = useRef<HTMLDivElement>(null);
  const [selectedRegion, setSelectedRegion] =
    useState<SelectedFilterItem>(null);

  return (
    <div className={styles.container}>
      <MapHeader
        type={params?.type || ''}
        title={title}
        hasFilter={!!params?.codes?.length}
        headerRef={headerRef}
        selectedItem={selectedRegion}
      />

      {params?.data?.length ? (
        <Map
          data={data}
          headerRef={headerRef}
          willCaptureImage={willCaptureImage}
          selectedRegion={selectedRegion}
          onSelectRegion={setSelectedRegion}
        />
      ) : (
        <WidgetEmptyState />
      )}
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { SwipeableDrawer } from '@mui/material';
import { ReactComponent as ChatIcon } from 'assets/chat.svg';
import { ReactComponent as ArrowIcon } from 'assets/chevron.svg';
import { ReactComponent as ResearchIcon } from 'assets/document-signed.svg';
import { ReactComponent as ReportIcon } from 'assets/insight.svg';
import { ReactComponent as LogoIcon } from 'assets/logo-sidebar.svg';
import { ReactComponent as CRMBoosterIcon } from 'assets/plant-growth.svg';
import cn from 'classnames';
import { Routes } from 'enums/Routes';
import { useAuthContext, useIsExternalUserDashboard } from 'hooks';
import { useUserInfo } from 'hooks/api';
import { useSidebarContext } from 'hooks/useSidebarContext';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { APPOINTMENT_CALENDAR_URL } from 'utils/constants';

import { DashboardsInfo } from '../Header/DashboardsInfo';
import { ResearchItemContent } from './ResearchItemContent';

import styles from './styles.module.scss';

const limitedHeaderRoutes = [
  Routes.Checkout,
  Routes.OneTimePaymentCheckout,
  Routes.ReportCheckout
];

export const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { isAuthenticated } = useAuthContext();
  const { data: userInfo } = useUserInfo();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const showOnlyLogo = limitedHeaderRoutes.includes(
    location.pathname as Routes
  );

  const isUserVerified = userInfo?.hasCompleteInfo && isAuthenticated;
  const showExtraData = isUserVerified && !showOnlyLogo;

  const { open, openSidebar, closeSidebar } = useSidebarContext();

  const menuRows = [
    {
      icon: <ReportIcon className={styles.icon} />,
      title: t('Common.Sidebar.Research'),
      link: Routes.NewChat,
      isRouteMatch: () =>
        [Routes.NewChat, Routes.ResearchHistory].find((route) =>
          route.includes(location.pathname)
        ) || location.pathname.includes('chats'),
      content: <ResearchItemContent open={open} />
    },
    {
      icon: <ResearchIcon className={styles.icon} />,
      title: t('Common.Sidebar.Report'),
      link: Routes.Reports,
      isRouteMatch: () => location.pathname === Routes.Reports
    },
    {
      icon: <CRMBoosterIcon className={styles.icon} />,
      title: t('Common.Sidebar.CRMBooster'),
      link: Routes.CRMBooster,
      isRouteMatch: () => location.pathname === Routes.CRMBooster
    }
  ];

  const onArrowClick = () => {
    if (open) {
      closeSidebar();
      return;
    }

    openSidebar();
  };

  const onRequestCallClick = () => {
    mixpanel?.track(MixpanelEvents.TalkWithAnExpertRequest, {
      Place: 'Sidebar'
    });
    window.open(APPOINTMENT_CALENDAR_URL, '_blank');
  };

  if (!showExtraData || isExternalUserDashboard) {
    return null;
  }

  return (
    <SwipeableDrawer
      open={open}
      anchor="left"
      onOpen={openSidebar}
      onClose={closeSidebar}
      elevation={0}
      disableEnforceFocus
      disableScrollLock
      disableRestoreFocus
      classes={{
        paper: cn(styles.paper, !open && styles.collapsed),
        root: styles.root
      }}
      disableBackdropTransition
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
            display: 'none'
          }
        }
      }}
    >
      <div
        className={cn(styles.container, !open && styles.collapsed)}
        data-testid="sidebar-content"
      >
        <div onClick={onArrowClick} className={styles['close-container']}>
          <ArrowIcon data-testid="close" className={styles.close} />
        </div>
        <div>
          <div className={styles['logo-container']}>
            <Link
              data-testid="logo"
              to={Routes.NewChat}
              className={styles.logo}
            >
              <LogoIcon />
            </Link>
          </div>
          <DashboardsInfo collapsed={!open} />
        </div>

        <div className={styles.content}>
          {menuRows.map((row) => {
            return (
              <div key={row.title}>
                <Link
                  to={row.link}
                  className={cn(
                    styles['menu-item'],
                    row.isRouteMatch() && styles.active
                  )}
                >
                  <div className={styles['icon-container']}>
                    {row.icon}
                    <span className={styles.title}>{row.title}</span>
                  </div>
                  <div className={styles.plus}>+</div>
                </Link>
                {row.content}
              </div>
            );
          })}
        </div>
        <div className={styles.footer}>
          <div className={styles['menu-item']} onClick={onRequestCallClick}>
            <div className={styles['icon-container']}>
              <ChatIcon className={styles.icon} />
              <span>{t('Common.Sidebar.BookConsultation')}</span>
            </div>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import requestReportImage from 'assets/request-report.png';
import { Routes } from 'enums';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

export const RequestReportCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToNewChat = () => {
    mixpanel?.track(MixpanelEvents.RequestReportClick);
    navigate(Routes.NewChat);
  };

  return (
    <div className={styles.container}>
      <div>
        <h3 className={styles.title}>
          {t('Page.Report.RequestReportCard.Title')}
        </h3>
        <img
          alt="requestReportImage"
          src={requestReportImage}
          className={styles.image}
        />
        <div className={styles.description}>
          {t('Page.Report.RequestReportCard.Description')}
        </div>
      </div>
      <Button
        fullWidth
        size="medium"
        type="submit"
        color="primary"
        variant="contained"
        onClick={goToNewChat}
      >
        {t('Page.Report.RequestReportCard.RequestReport')}
      </Button>
    </div>
  );
};

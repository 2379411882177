import { createContext, RefObject, useContext } from 'react';

interface ContextData {
  hideExpertButton: boolean;
  showExtendedExpertButton: boolean;
  expertWidgetRef: RefObject<HTMLDivElement> | null;
}

export const DashboardContext = createContext<ContextData>({
  expertWidgetRef: null,
  hideExpertButton: false,
  showExtendedExpertButton: false
});

export const DashboardContextProvider = DashboardContext.Provider;

export const useDashboardContext = (): ContextData =>
  useContext(DashboardContext);

import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { InsightsType } from 'enums';
import type { SelectedFilterItem } from 'types';

import { WidgetFilter } from '../WidgetFilter';
import { ZoomableSunburst } from './ZoomableSunburst';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  className?: string;
  data?: DashboardParams;
}

export const ZoomableSunburstChart: FC<Props> = ({
  title,
  data,
  className
}) => {
  const { t } = useTranslation();

  const zoomableSunburstContainerRef = useRef<HTMLDivElement | null>(null);

  const [selectedItem, setSelectedItem] = useState<SelectedFilterItem>(null);

  const selectItem = (data: SelectedFilterItem) => {
    setSelectedItem(data);
  };

  return (
    <div className={cn(styles.container, className)}>
      <CommonTooltip
        title={title || t('Page.Dashboard.ZoomableSunburst.Title')}
        className={cn(styles['main-title'], 'overflowed-text-multiline')}
      />
      <WidgetFilter
        type={data?.type || ''}
        className={styles.header}
        tooltip={t('Page.Dashboard.ZoomableSunburst.ChooseFilter')}
        selectedItem={selectedItem}
        widgetType={InsightsType.HierarchyIndustries}
        notes={{
          active: t('Page.Dashboard.ZoomableSunburst.NoteActive', {
            industry: selectedItem?.name || '-'
          }),
          inactive: t('Page.Dashboard.ZoomableSunburst.Note')
        }}
      />
      {data?.data?.length ? (
        <div
          ref={zoomableSunburstContainerRef}
          className={styles['zoomable-sunburst-chart-container']}
        >
          <ZoomableSunburst data={data.data[0]} selectItem={selectItem} />
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </div>
  );
};

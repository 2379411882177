import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

export const AuthLayout: FC<Props> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.overlay}>{children}</div>
  </div>
);

import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { DashboardContextProvider } from 'hooks/useDashboardContext';

const DASHBOARD_INDENTATIONS = {
  top: -100,
  bottom: 48
};

const EXTENDED_EXPERT_BUTTON_TIMEOUT = 3000;

export const DashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const expertWidgetRef = useRef<HTMLDivElement>(null);
  const [hideFloatingButton, setHideFloatingButton] = useState<boolean>(true);
  const [showExtendedExpertButton, setShowExtendedButton] =
    useState<boolean>(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | number;

    const handleScroll = () => {
      setShowExtendedButton(false);

      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setShowExtendedButton(true);
      }, EXTENDED_EXPERT_BUTTON_TIMEOUT);

      if (expertWidgetRef.current) {
        const rect = expertWidgetRef.current.getBoundingClientRect();
        setHideFloatingButton(
          rect.top >= DASHBOARD_INDENTATIONS.top &&
            rect.bottom + DASHBOARD_INDENTATIONS.bottom <= window.innerHeight
        );
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <DashboardContextProvider
      value={{
        expertWidgetRef,
        hideExpertButton: hideFloatingButton,
        showExtendedExpertButton
      }}
    >
      {children}
    </DashboardContextProvider>
  );
};

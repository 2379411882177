import { Dispatch, SetStateAction, useMemo } from 'react';
import { MapTypes } from 'enums';

import { DataGroup } from './useMapDataGroups';

interface Props {
  filters?: string[];
  mapType: MapTypes;
  dataGroups: DataGroup[];
  activeYear: number | null;
  setActiveYear: Dispatch<SetStateAction<number | null>>;
}

export const useFoundationYearFilter = ({
  mapType,
  filters,
  activeYear,
  dataGroups,
  setActiveYear
}: Props) =>
  useMemo(() => {
    const isDotsMap = mapType === MapTypes.Dots;

    const filteredDataGroups = dataGroups.filter((group) => {
      const isInFilter =
        filters?.length && filters?.includes(group.name || group.label);

      if (!isDotsMap) {
        return !group.isOther && isInFilter;
      }

      return isInFilter;
    });

    const foundationYearsArray = filteredDataGroups
      .reduce(
        (acc, currentGroup) => {
          return acc.concat(currentGroup.value.map((value) => value[2]));
        },
        [] as Array<number | undefined>
      )
      .filter((year) => year !== undefined) as number[];

    const foundationYears = Array.from(
      new Set(foundationYearsArray)
    ) as number[];

    const startYear = Math.min(...foundationYears);
    const endYear = Math.max(...foundationYears);

    const foundationsByYear: { [key: number]: number } = {};

    for (let year = startYear; year <= endYear; year += 1) {
      foundationsByYear[year] = 0;
    }

    foundationYearsArray.forEach((year) => {
      if (year in foundationsByYear) {
        foundationsByYear[year] += 1;
      }
    });

    if (!activeYear) {
      setActiveYear(endYear);
    }

    return {
      endYear,
      startYear,
      foundationsByYear,
      isFoundationYearsAvailable: !!foundationYears.length
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGroups, filters]);

import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchCoins } from 'actions/commonActions';
import { AdminHeader } from 'components/AdminHeader';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { queryKeys, Routes } from 'enums';
import { useIsExternalUserDashboard } from 'hooks';
import {
  useFetchBaseExternalDashboard,
  useFetchExternalDashboardById,
  useUserInfo
} from 'hooks/api';
import { useFetchDashboardData } from 'hooks/api/useFetchDashboardData';
import { queryClient } from 'index';

import { DashboardV1 } from '../DashboardV1';
import { DashboardV2 } from '../DashboardV2';

export type DashboardComponentExtended = DashboardComponent & {
  className?: string;
  iconClassName?: string;
};

export const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { dashboardId, messageId, chatId } = useParams();
  const isExternalUserDashboard = useIsExternalUserDashboard();

  if (!dashboardId && !messageId) navigate(Routes.NewChat);

  const { data: userInfo } = useUserInfo();

  const {
    isError: isDashboardError,
    isPending: isDashboardPending,
    isSuccess: isDashboardSuccess,
    mutate: fetchDashboardData
  } = useFetchDashboardData();

  const {
    isError: isExternalDashboardError,
    isPending: isExternalDashboardPending,
    isSuccess: isExternalDashboardSuccess,
    mutate: fetchBaseExternalDashboard
  } = useFetchBaseExternalDashboard();

  const {
    isError: isNestedExternalDashboardError,
    isPending: isNestedExternalDashboardPending,
    isSuccess: isNestedExternalDashboardSuccess,
    mutate: fetchExternalDashboardById
  } = useFetchExternalDashboardById();

  const { isError, isPending, isSuccess } = {
    isError: isExternalUserDashboard
      ? isExternalDashboardError || isNestedExternalDashboardError
      : isDashboardError,
    isPending: isExternalUserDashboard
      ? isExternalDashboardPending || isNestedExternalDashboardPending
      : isDashboardPending,
    isSuccess: isExternalUserDashboard
      ? isExternalDashboardSuccess || isNestedExternalDashboardSuccess
      : isDashboardSuccess
  };

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  useEffect(() => {
    if (!isExternalUserDashboard && dashboardId) {
      fetchDashboardData({ dashboardId });
    } else if (chatId && messageId) {
      fetchBaseExternalDashboard({ chatId, messageId });
    } else if (chatId && dashboardId) {
      fetchExternalDashboardById({ dashboardId });
    }
  }, [
    chatId,
    fetchDashboardData,
    dashboardId,
    isExternalUserDashboard,
    messageId,
    fetchBaseExternalDashboard,
    fetchExternalDashboardById
  ]);

  useEffect(() => {
    if (isError) {
      navigate(Routes.NewChat);
    }
  }, [isError, navigate]);

  useEffect(() => {
    if (isSuccess && userInfo?.isFreeUser) {
      queryClient.invalidateQueries({ queryKey: queryKeys.userCoins });
      queryClient.fetchQuery({
        queryKey: queryKeys.userCoins,
        queryFn: fetchCoins
      });
    }
  }, [isSuccess, userInfo?.isFreeUser]);

  return (
    <>
      {isExternalUserDashboard && <AdminHeader />}
      {dashboardData?.revision === 1 ? (
        <DashboardV1 isPending={isPending} />
      ) : (
        <DashboardV2 isPending={isPending} />
      )}
    </>
  );
};

import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, Skeleton } from '@mui/material';
import { trackActivity } from 'actions/userActions';
import cn from 'classnames';
import { Loader, Tooltip } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import { Routes } from 'enums/Routes';
import { useUserCoins, useUserInfo } from 'hooks/api';
import { useNestedDashboards } from 'hooks/api/useNestedDashboards';
import { queryClient } from 'index';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

interface Props {
  data: DashboardData;
}

export const ChatCardCollapse: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const [nestedDashboards, setNestedDashboards] = useState<DashboardData[]>([]);

  const prevNestedDashboards: DashboardData[] | undefined =
    queryClient.getQueryData(queryKeys.nestedDashboards(data.id));

  const {
    isPending: isNestedDashboardsFetching,
    mutate: fetchNestedDashboards
  } = useNestedDashboards(setNestedDashboards);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    if (!prevNestedDashboards) {
      fetchNestedDashboards({
        dashboardId: data.id
      });
    } else {
      setNestedDashboards(prevNestedDashboards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevNestedDashboards]);

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const expandChatCard = useCallback(() => {
    setExpanded(true);

    if (!prevNestedDashboards) {
      fetchNestedDashboards({
        dashboardId: data.id
      });
    } else {
      setNestedDashboards(prevNestedDashboards);
    }
  }, [prevNestedDashboards, fetchNestedDashboards, data.id]);

  const collapseChatCard = useCallback(() => setExpanded(false), []);

  const renderDashboardIndicator = (isPaid?: boolean | null) => (
    <Tooltip
      enterTouchDelay={0}
      placement="right"
      className={styles['tooltip-indicator']}
      title={
        isPaid
          ? t('Common.UnlockedDashboardTooltip')
          : t('Common.LockedDashboardTooltip')
      }
    >
      <div className={cn(styles.indicator, isPaid && styles.filled)} />
    </Tooltip>
  );

  const onClick = (dashboardId: string, isPaid?: boolean) => {
    if (!isPaid) {
      mixpanel?.track(MixpanelEvents.DashboardCreated, {
        'Dashboard ID': dashboardId,
        'User plan': userInfo?.subscriptionPlan,
        // eslint-disable-next-line no-restricted-globals
        'Dashboard link': `${location.origin}/admin/chats/${data.chatId}/dashboards/${dashboardId}`,
        'Default Industries': data?.rootIndustries?.join(', ') || '-',
        'Nested Industries': data?.nestedIndustries?.join(', ') || '-'
      });

      trackActivity();
    }

    navigate(
      Routes.Dashboard.replace(':dashboardId', dashboardId).replace(
        ':chatId',
        data?.chatId || ''
      )
    );
  };

  const isDashboardCanBeOpened =
    (userInfo?.isFreeUser && (userCoins?.count || data.isPaid)) ||
    userInfo?.isPremiumUser;

  return (
    <>
      <div className={styles.content}>
        {!data.title ? (
          <Skeleton variant="text" className={styles.skeleton} />
        ) : (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <p
            className={cn(
              styles.label,
              !isDashboardCanBeOpened && styles.disabled
            )}
            data-testid={data.id}
            onClick={() =>
              isDashboardCanBeOpened && onClick(data.id, data.isPaid)
            }
          >
            {renderDashboardIndicator(data.isPaid)}
            <Tooltip
              disabled={userInfo?.isPremiumUser || data.isPaid}
              enterTouchDelay={0}
              placement="bottom"
              title={
                userCoins?.count
                  ? t('Common.LockedDashbordChargeTooltip')
                  : t('Common.FreeSubscriptionFullDashboardButtonTooltip')
              }
            >
              <div className={cn(styles['label-text'], 'overflowed-text')}>
                {data.title}
              </div>
            </Tooltip>
          </p>
        )}
        {data.hasNestedDashboards && (
          <div
            className={styles['arrow-icon-wrapper']}
            onClick={expanded ? collapseChatCard : expandChatCard}
          >
            <ExpandMoreIcon
              className={cn(styles['arrow-icon'], expanded && styles.expanded)}
            />
          </div>
        )}
      </div>
      <Collapse
        unmountOnExit
        in={expanded}
        timeout={{
          enter: 0
        }}
        className={styles.rows}
      >
        {isNestedDashboardsFetching ? (
          <Loader />
        ) : (
          (nestedDashboards || []).map((nestedDashboard) => (
            <div key={nestedDashboard.id} className={styles.row}>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <p
                onClick={() =>
                  onClick(nestedDashboard.id, nestedDashboard.isPaid)
                }
                data-testid="question"
                className={cn(
                  styles.question,
                  !nestedDashboard.title && styles.disabled
                )}
              >
                {!nestedDashboard.title ? (
                  <Skeleton variant="text" className={styles.skeleton} />
                ) : (
                  <div
                    data-testid="question"
                    className={cn(styles['question-text'], 'overflowed-text')}
                  >
                    {nestedDashboard.title}
                  </div>
                )}
              </p>
            </div>
          ))
        )}
      </Collapse>
    </>
  );
};

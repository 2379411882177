import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as CheckmarkOrangeIcon } from 'assets/checkmark-orange.svg';
import fullReportImage from 'assets/full-report.png';
import cn from 'classnames';
import { Card } from 'components';
import { Routes } from 'enums';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { Stepper } from '../Stepper';

import styles from './styles.module.scss';

const planData = {
  description: 'Page.Pricing.CRMBooster.Premium.Subtitle',
  subdescription: 'Page.Pricing.CRMBooster.Premium.Subdescription',
  features: [
    {
      title: 'Page.Pricing.Report.Full.Feature.First',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Report.Full.Feature.Second',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Report.Full.Feature.Third',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Report.Full.Feature.Fourth',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Report.Full.Feature.Fifth',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    }
  ]
};

const steps = [
  {
    title: 'Page.Pricing.Report.Full.Steps.First.Title',
    description: 'Page.Pricing.Report.Full.Steps.First.Description'
  },
  {
    title: 'Page.Pricing.Report.Full.Steps.Second.Title',
    description: 'Page.Pricing.Report.Full.Steps.Second.Description'
  },
  {
    title: 'Page.Pricing.Report.Full.Steps.Third.Title',
    description: 'Page.Pricing.Report.Full.Steps.Third.Description'
  },
  {
    title: 'Page.Pricing.Report.Full.Steps.Fourth.Title',
    description: 'Page.Pricing.Report.Full.Steps.Fourth.Description'
  }
];

export const FullReportCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRequestFullReport = () => {
    mixpanel?.track(MixpanelEvents.PricingPageRequestFullReport);

    navigate(Routes.NewChat);
  };

  return (
    <Card className={cn(styles.card)}>
      <div className="full-width">
        <div className={styles['header-container']}>
          <h3 className={styles.title}>
            {t('Page.Pricing.Report.Full.Title')}
          </h3>
          <div className={styles['price-container']}>
            <h5 className={styles['prev-price']}>€299</h5>
            <h3 className={styles.price}>€149</h3>
          </div>
        </div>
        <div className={styles['description-container']}>
          <p className={styles.description}>
            <Trans i18nKey="Page.Pricing.Report.Full.Price">
              Comprehensive insights for smarter decisions—just
              <span className={styles.highlighted}>€149</span>
              (save
              <span className={styles.highlighted}>€149</span>
              !)
            </Trans>
          </p>
          <p className={styles.subdescription}>
            {t('Page.Pricing.Report.Full.Subtitle')}
          </p>
        </div>
        <div className={styles['features-container']}>
          <div>
            {planData.features.map(({ title, icon }) => (
              <div key={title.toString()} className={styles.feature}>
                {icon}
                <span>{t(title.toString())}</span>
              </div>
            ))}
          </div>
          <img
            alt="full-report"
            src={fullReportImage}
            className={styles.image}
          />
        </div>
      </div>

      <Stepper steps={steps} />

      <Button
        size="large"
        color="primary"
        variant="contained"
        className={styles.button}
        onClick={handleRequestFullReport}
      >
        {t('Page.Pricing.Report.Full.BuyReport')}
      </Button>
    </Card>
  );
};

import { ApiBase } from '../ApiBase';

export interface CRMBoosterData {
  firstName?: string;
  lastName: string;
  email: string;
  industryCustom: string;
  additionalInfo: string;
  lang: 'en' | 'de';
  isSubscribedToNewsletter?: boolean;
  isAgreedOnTermsAndConditions: boolean;
}

class SalesforceApi extends ApiBase {
  requestCRMBooster(data: CRMBoosterData) {
    return this.client.post<void>('/request-crm-booster-form', data);
  }
}

const instance = new SalesforceApi('/salesforce');

export { instance as SalesforceApi };

import { FC, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { useMobile, useTablet } from 'hooks';
import type { SelectedFilterItem } from 'types';

import { renderZoomableTreemapChart } from './renderZoomableTreemapChart';

import './styles.scss';

type Dimensions = {
  width: number;
  height: number;
};

interface Props {
  data: DashboardParamsData;
  selectItem: (selectedItem: SelectedFilterItem) => void;
  chartColors: { start: string; end: string; root: string };
  dimensions?: {
    mobile: Dimensions;
    tablet: Dimensions;
    desktop: Dimensions;
  };
}

const MOBILE_DIMENSIONS = {
  width: 800,
  height: 1200
};

const TABLET_DIMENSIONS = {
  width: 1000,
  height: 650
};

const DESKTOP_DIMENSIONS = {
  width: 1000,
  height: 650
};

export const ZoomableTreemap: FC<Props> = ({
  data,
  selectItem,
  chartColors,
  dimensions = {
    mobile: MOBILE_DIMENSIONS,
    tablet: TABLET_DIMENSIONS,
    desktop: DESKTOP_DIMENSIONS
  }
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  // eslint-disable-next-line no-nested-ternary
  const { width, height } = isMobile
    ? dimensions.mobile
    : isTablet
    ? dimensions.tablet
    : dimensions.desktop;
  const svgRef = useRef<SVGGElement>(null);
  const modifiedData = {
    name: data.name,
    children: data.children
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    renderZoomableTreemapChart({
      width,
      height,
      isMobile,
      selectItem,
      chartColors,
      data: modifiedData,
      svgElement: svg
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <svg
      // @ts-expect-error svg type
      ref={svgRef}
      style={{
        maxWidth: width,
        maxHeight: height + 99.5
      }}
      viewBox={`0.5, -100.5, ${width}, ${height + 99.5}`}
    />
  );
};

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { fetchReports } from 'actions/reportsActions';
import { Report } from 'entities/Report.entity';
import { queryKeys } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';
import { FetchReportsFilters } from 'services/API/Reports/ReportsApi';

const defaultReportsLimit = 10;
const defaultReportsOffset = 0;

export const usePaginatedReports = (filters?: FetchReportsFilters) => {
  const { isAuthenticated } = useAuthContext();

  return useInfiniteQuery<Report[]>({
    enabled: isAuthenticated,
    queryKey: queryKeys.paginatedReports(filters),
    queryFn: ({ pageParam }) => {
      const offset = pageParam as number;
      const limit = filters?.limit || defaultReportsLimit;

      return fetchReports({
        ...filters,
        limit,
        offset
      });
    },
    initialPageParam: filters?.offset || defaultReportsOffset,
    getNextPageParam: (lastPage, pages) => {
      const limit = filters?.limit || defaultReportsLimit;
      const nextOffset = limit * pages.length;

      if (lastPage?.length < limit) return null;

      return nextOffset;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: 1,
    placeholderData: keepPreviousData
  });
};

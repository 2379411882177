import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlansIcon } from 'assets/boxes.svg';
import { ReactComponent as ReportIcon } from 'assets/document-signed-small.svg';
import { ReactComponent as CRMBoosterIcon } from 'assets/plant-growth-small.svg';
import cn from 'classnames';
import { PricingTabs } from 'enums/PricingTabs.enum';

import styles from './styles.module.scss';

const pricingTabs = [
  {
    id: PricingTabs.Plans,
    title: 'Page.Pricing.Tabs.Plans',
    icon: <PlansIcon />
  },
  {
    id: PricingTabs.Report,
    title: 'Page.Pricing.Tabs.Report',
    icon: <ReportIcon />
  },
  {
    id: PricingTabs.CRMBooster,
    title: 'Page.Pricing.Tabs.CRMBooster',
    icon: <CRMBoosterIcon />
  }
];

interface Props {
  activeTab: string;
  changeTab: (tab: string) => void;
}

export const Tabs: FC<Props> = ({ activeTab, changeTab }) => {
  const { t } = useTranslation();

  const switchTab = (id: string) => {
    changeTab(id);
  };

  return (
    <div className={styles.tabs}>
      <div className={styles['tabs-list']}>
        {pricingTabs.map(({ id, title, icon }) => (
          <div
            key={id}
            onClick={() => switchTab(id)}
            className={cn(styles.tab, activeTab === id && styles.active)}
          >
            {icon}
            <span>{t(title)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

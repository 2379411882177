import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { APPOINTMENT_CALENDAR_URL } from 'utils/constants';

import styles from './styles.module.scss';

export const LimitedCompanyListNote = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <strong className={styles.title}>
        {t('Page.Chat.LimitedCompanyListNote.Title')}
      </strong>
      <span className={styles.description}>
        {t('Page.Chat.LimitedCompanyListNote.Description')}
      </span>
      <a className={styles.cta} target="_blanc" href={APPOINTMENT_CALENDAR_URL}>
        {t('Page.Chat.LimitedCompanyListNote.Cta')}
      </a>
    </div>
  );
});

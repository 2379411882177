import { date, deserialize, serializable } from 'serializr';

export class Report {
  @serializable
  id: string = '';

  @serializable
  userId: string = '';

  @serializable
  title?: string = '';

  @serializable
  description?: string = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable
  filename?: string = '';

  @serializable
  mimetype?: string = '';

  static deserialize(json: Object | string): Report {
    return deserialize(Report, json);
  }
}

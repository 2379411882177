import { useMutation } from '@tanstack/react-query';
import { archiveChat } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';
import { DEFAULT_SIDEBAR_CHATS_FILTERS } from 'utils/constants';

interface Props {
  id: string;
  hasSuccessSnackbar?: boolean;
  refetchChats?(): void;
}
export const useArchiveChat = ({
  id,
  refetchChats,
  hasSuccessSnackbar
}: Props) => {
  const snackbar = useSnackbar();

  return useMutation<Chat, ApiError, { isArchived: boolean }>({
    mutationFn: ({ isArchived }) => archiveChat(id, { isArchived }),
    onSuccess() {
      if (refetchChats) {
        refetchChats();
      }

      queryClient.refetchQueries({
        queryKey: queryKeys.paginatedChats(DEFAULT_SIDEBAR_CHATS_FILTERS)
      });

      if (hasSuccessSnackbar) {
        snackbar.success.chatArchivationSuccess();
      }
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};

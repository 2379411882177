import {
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  useMemo,
  useRef,
  useState
} from 'react';
import MapGl, { type MapRef, NavigationControl } from 'react-map-gl';
import cn from 'classnames';
import { ClusterMapLayer } from 'components/Map/ClusterMapLayer';
import { CompaniesLayer } from 'components/Map/CompaniesLayer';
import { MAP_PROJECTION } from 'components/Map/constants/mapSettings';
import { DotsMapLayer } from 'components/Map/DotsMapLayer';
import { FilterMapLayer } from 'components/Map/FilterMapLayer';
import { FoundationYearFilter } from 'components/Map/FoundationYearFilter';
import { HeatMapLayer } from 'components/Map/HeatMapLayer';
import { useFoundationYearFilter } from 'components/Map/hooks/useFoundationYearFilter';
import { MapBoundaries, MapSizes, useMap } from 'components/Map/hooks/useMap';
import { useMapDataGroups } from 'components/Map/hooks/useMapDataGroups';
import { MapHeatScale } from 'components/Map/MapHeatScale';
import { MapLegend } from 'components/Map/MapLegend';
import { MapTypeSelect } from 'components/Map/MapTypeSelect';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { MapTypes } from 'enums';
import type { SelectedFilterItem } from 'types';

import styles from './styles.module.scss';

export type MapData = Pick<
  DashboardComponent,
  'id' | 'mapType' | 'params' | 'filters'
>;

interface Props {
  data: MapData;
  className?: string;
  headerRef?: RefObject<HTMLDivElement>;
  willCaptureImage?: boolean;
  selectedRegion: SelectedFilterItem;
  disableAllFilter?: boolean;
  disableFoundationYears?: boolean;
  onSelectRegion: Dispatch<SetStateAction<SelectedFilterItem>>;
}

export const Map: FC<Props> = ({
  data,
  headerRef,
  className,
  selectedRegion,
  willCaptureImage,
  disableAllFilter,
  disableFoundationYears,
  onSelectRegion
}) => {
  const mapRef = useRef<MapRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [mapType, setMapType] = useState<MapTypes>(MapTypes.Dots);
  const [mapSizes, setMapSizes] = useState<MapSizes | null>(null);
  const [activeYear, setActiveYear] = useState<number | null>(null);
  const [groupFilter, setGroupFilter] = useState<string[]>([]);
  const [mapBoundaries, setMapBoundaries] = useState<MapBoundaries | null>(
    null
  );

  const { handleMapLoad } = useMap({
    data: data?.params?.data,
    mapType: data?.mapType,
    mapRef,
    filters: data?.filters,
    mapSizes,
    headerRef,
    containerRef,
    mapBoundaries,
    willCaptureImage,
    setMapType,
    setMapSizes,
    setGroupFilter,
    onSelectRegion,
    setMapBoundaries
  });

  const dataGroups = useMapDataGroups({
    data: data?.params?.data,
    mapType,
    disableAllFilter
  });

  const { startYear, endYear, foundationsByYear, isFoundationYearsAvailable } =
    useFoundationYearFilter({
      filters: data?.filters,
      mapType,
      activeYear,
      dataGroups,
      setActiveYear
    });

  const mapDataVisualisation = useMemo(() => {
    switch (mapType) {
      case MapTypes.Dots:
        return (
          <DotsMapLayer
            mapType={mapType}
            dataGroups={dataGroups}
            activeYear={activeYear}
            groupFilter={groupFilter}
          />
        );
      case MapTypes.Heat:
        return (
          <HeatMapLayer
            dataGroups={dataGroups}
            activeYear={activeYear}
            groupFilter={groupFilter}
          />
        );
      case MapTypes.Cluster:
        return (
          <ClusterMapLayer
            dataGroups={dataGroups}
            activeYear={activeYear}
            groupFilter={groupFilter}
          />
        );
      default:
        return null;
    }
  }, [mapType, dataGroups, activeYear, groupFilter]);

  return (
    <div className={cn(styles.map, className)}>
      <div ref={containerRef}>
        <MapGl
          ref={mapRef}
          scrollZoom={false}
          dragRotate={false}
          style={{ ...mapSizes }}
          onLoad={handleMapLoad}
          mapStyle="mapbox://styles/mapbox/light-v11"
          projection={MAP_PROJECTION}
          preserveDrawingBuffer={willCaptureImage}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
          <NavigationControl showCompass={false} position="top-left" />

          {!!data?.params?.codes?.length && (
            <FilterMapLayer
              codes={data.params.codes}
              selectedRegion={selectedRegion}
            />
          )}

          {mapDataVisualisation}

          {!!data?.params?.companies?.length && (
            <CompaniesLayer companies={data.params.companies} />
          )}

          <MapTypeSelect
            mapType={mapType}
            widgetId={data.id}
            groupFilter={groupFilter}
            setMapType={setMapType}
          />
          <MapHeatScale mapType={mapType} />
          <MapLegend
            widgetId={data.id}
            mapType={mapType}
            dataGroups={dataGroups}
            groupFilter={groupFilter}
            setGroupFilter={setGroupFilter}
          />
        </MapGl>

        {isFoundationYearsAvailable && !disableFoundationYears && (
          <FoundationYearFilter
            width={containerRef.current?.offsetWidth || 0}
            endYear={endYear}
            startYear={startYear}
            activeYear={Number(activeYear)}
            setActiveYear={setActiveYear}
            foundationsByYear={foundationsByYear}
          />
        )}
      </div>
    </div>
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { MapTypes } from 'enums';
import { useFetchOtherMapData } from 'hooks/api/useFetchOtherMapData';

import { MAX_GROUPS_COUNT } from '../constants/mapSettings';

export type DataGroup = DashboardParamsData & { isOther?: boolean };

interface Props {
  mapType: MapTypes;
  data?: DashboardParamsData[] | null;
  disableAllFilter?: boolean;
}

export const useMapDataGroups = ({
  data,
  mapType,
  disableAllFilter
}: Props): DataGroup[] => {
  const { t } = useTranslation();
  const { data: otherMapData } = useFetchOtherMapData();

  const otherDataGroup = useMemo(() => {
    if (!otherMapData || disableAllFilter) return null;

    return {
      name: 'All',
      label: t('Page.Dashboard.Map.AllGroupLabel'),
      value: otherMapData,
      isOther: true
    };
  }, [otherMapData, disableAllFilter, t]);

  const groups = useMemo(() => {
    const groups: Array<DataGroup> = [
      ...(data || []).slice(0, MAX_GROUPS_COUNT)
    ];

    if (mapType === MapTypes.Dots && otherDataGroup) {
      groups.push(otherDataGroup);
    }

    return groups;
  }, [data, otherDataGroup, mapType]);

  return groups;
};

import { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal, Tooltip } from 'components';
import { Routes } from 'enums';
import { useLanguage } from 'hooks';
import { useCreateFreeSnippet } from 'hooks/api/useCreateFreeSnippet';
import { useIsFreeSnippetAvailable } from 'hooks/useIsFreeSnippetAvailable';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { SAMPLE_REPORT_DE_URL, SAMPLE_REPORT_EN_URL } from 'utils/constants';

import { RequestFreeReportSuccessModal } from './RequestFreeReportSuccessModal';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  industry?: string;
  question: string;
}

export const RequestReportModal: FC<Props> = ({
  isOpen,
  onClose,
  industry,
  question
}) => {
  const preferredLanguage = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { chatId, dashboardId } = useParams();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const getTrackPlace = () => {
    const { pathname } = window.location;
    let place = '-';

    if (pathname.includes('chat')) place = 'Chat';
    if (pathname.includes('dashboard')) place = 'Dashboard';

    return place;
  };

  const onSuccess = () => {
    mixpanel?.track(MixpanelEvents.SuccessfulRequestForFreeSnippet, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
    onClose();
    setIsSuccessModalOpen(true);
  };

  const onError = () => {
    mixpanel?.track(MixpanelEvents.UnsuccessfulRequestForFreeSnippet, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
  };

  const { mutate: requestFreeSnippet } = useCreateFreeSnippet(
    onSuccess,
    onError
  );
  const { data: isFreeSnippetAvailable } = useIsFreeSnippetAvailable();

  const closeSuccessModal = useCallback(() => {
    setIsSuccessModalOpen(false);
  }, []);

  const createFreeSample = () => {
    mixpanel?.track(MixpanelEvents.RequestForFreeSnippet, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });

    requestFreeSnippet({
      industry,
      question
    });
  };

  const goToCheckout = () => {
    mixpanel?.track(MixpanelEvents.RequestForFullReport, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
    onClose();
    navigate({
      pathname: Routes.ReportCheckout,
      search: createSearchParams({
        industry: industry || '',
        question: question || ''
      }).toString()
    });
  };

  const sampleLink =
    preferredLanguage === 'en' ? SAMPLE_REPORT_EN_URL : SAMPLE_REPORT_DE_URL;

  return (
    <>
      <RequestFreeReportSuccessModal
        place={getTrackPlace()}
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleClassName={styles.title}
        title={t('Page.RequestReportModal.Title')}
      >
        <p className={styles.subtitle}>
          {t('Page.RequestReportModal.Subtitle')}
        </p>

        <div className={styles['main-content']}>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Page.RequestReportModal.Industry')}
            </span>
            <TextareaAutosize
              disabled
              maxRows={1}
              value={industry}
              className={cn(styles.container, 'capitalize')}
            />
          </div>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Page.RequestReportModal.AdditionalInformation')}
            </span>
            <TextareaAutosize
              disabled
              minRows={2}
              value={question}
              className={styles.container}
            />
          </div>
          <p className={cn(styles.subtitle, styles.indented)}>
            {t('Page.RequestReportModal.AdditionalInfo', {
              discountPrice: '€149',
              fullPrice: '€299'
            })}
          </p>

          <p className={styles['preview-info']}>
            <Trans i18nKey="Page.RequestReportModal.GetPreview">
              Need a preview? You can
              <a
                target="_blank"
                href={sampleLink}
                className={styles.highlighted}
                rel="noreferrer"
              >
                view a sample report
              </a>
              to see what you’ll get
            </Trans>
          </p>
        </div>

        <div className={styles.buttons}>
          <Tooltip
            placement="bottom-end"
            disabled={!!isFreeSnippetAvailable}
            title={t('Page.RequestReportModal.DisableFreeReportTooltip')}
          >
            <div className={styles.button}>
              <Button
                fullWidth
                size="medium"
                type="submit"
                color="primary"
                variant="outlined"
                onClick={createFreeSample}
                disabled={!isFreeSnippetAvailable}
              >
                {t('Page.RequestReportModal.GetFreeSnippet')}
              </Button>
            </div>
          </Tooltip>

          <Button
            size="medium"
            color="primary"
            variant="contained"
            className={styles.button}
            onClick={goToCheckout}
          >
            {t('Page.RequestReportModal.RequestFullReport')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

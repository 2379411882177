import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  placeholder: string;
  className?: string;
  buttonText?: string;
  onSubmit(query: string | null): void;
}

export const SearchFilterInput: FC<Props> = ({
  className,
  buttonText,
  placeholder,
  onSubmit
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {
    if (inputRef?.current) onSubmit(inputRef.current.value || null);
  };

  useEffect(() => {
    const handleEnterKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    };

    window.addEventListener('keydown', handleEnterKeyPress);

    return () => window.removeEventListener('keydown', handleEnterKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn(styles.container, className)}>
      <input
        type="text"
        ref={inputRef}
        className={styles.input}
        placeholder={placeholder}
      />
      <Button
        size="small"
        color="primary"
        variant="contained"
        className={styles.submit}
        onClick={handleSubmit}
      >
        {buttonText || t('Common.Search')}
      </Button>
    </div>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlaceholderIcon } from 'assets/plant-growth-big.svg';

import styles from './styles.module.scss';

export const CRMBoosterPlaceholder: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles['placeholder-state']}>
        <PlaceholderIcon />
        <h3 className={styles['placeholder-state-title']}>
          {t('Page.CRMBooster.Placeholder.Title')}
        </h3>
        <span className={styles['placeholder-state-description']}>
          {t('Page.CRMBooster.Placeholder.Description')}
        </span>
      </div>
    </div>
  );
};

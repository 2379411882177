import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Checkbox, FormHelperText } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/CheckIcon2.svg';
import { ReactComponent as UncheckIcon } from 'assets/uncheck-small.svg';
import cn from 'classnames';
import { Modal, SubTitle } from 'components';
import { useLandingUrl } from 'hooks';
import { useUpdateUserInfo } from 'hooks/api/useUpdateUserInfo';
import { useUserInfo } from 'hooks/api/useUserInfo';

import styles from './styles.module.scss';

export type ActionRequiredFormData = {
  isSubscribedToNewsletter: boolean;
  isAgreedOnTermsAndConditions?: boolean;
};

export const ActionRequiredModal = () => {
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const { mutate: updateUserInfo } = useUpdateUserInfo(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const landingUrl = useLandingUrl();
  const actionRequiredFormMethods = useForm<ActionRequiredFormData>();
  const {
    handleSubmit,
    formState: { errors }
  } = actionRequiredFormMethods;

  const onSubmit = ({ isSubscribedToNewsletter }: ActionRequiredFormData) => {
    setIsOpen(false);

    updateUserInfo({ isSubscribedToNewsletter });
  };

  useEffect(() => {
    setIsOpen(
      Boolean(
        userInfo &&
          userInfo.isSubscribedToNewsletter == null &&
          !userInfo.isFirstLogin
      )
    );
  }, [userInfo]);

  return (
    <Modal
      title={t('Common.ActionRequiredModal.Title')}
      titleClassName={styles.title}
      className={styles.container}
      isOpen={isOpen}
    >
      <SubTitle className={styles.subtitle}>
        <Trans
          i18nKey="Common.ActionRequiredModal.Subtitle"
          components={{
            termsAndConditions: (
              <a
                href={`${landingUrl}/terms-and-conditions`}
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            ),
            privacyPolicy: (
              <a
                href={`${landingUrl}/privacy-policy`}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            )
          }}
        />
      </SubTitle>

      <FormProvider {...actionRequiredFormMethods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['checkbox-container']}>
            <Controller
              name="isAgreedOnTermsAndConditions"
              rules={{
                validate: (
                  value: ActionRequiredFormData['isAgreedOnTermsAndConditions']
                ) => {
                  if (!value) {
                    return t(
                      'Form.Validation.IsAgreedOnTermsAndConditions.Required'
                    );
                  }

                  return true;
                }
              }}
              render={({ field }) => (
                <div className={styles['checkbox-content']}>
                  <Checkbox
                    {...field}
                    color="primary"
                    id="isAgreedOnTermsAndConditions"
                    icon={<UncheckIcon />}
                    checkedIcon={<CheckIcon />}
                    disableRipple
                    classes={{
                      root: styles.checkbox
                    }}
                  />
                  <div className={styles['checkbox-label']}>
                    <Trans
                      i18nKey="Common.ActionRequiredModal.TermsAndConditionsLabel"
                      components={{
                        termsAndConditions: (
                          <a
                            href={`${landingUrl}/terms-and-conditions`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms of Service
                          </a>
                        ),
                        privacyPolicy: (
                          <a
                            href={`${landingUrl}/privacy-policy`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        )
                      }}
                    />
                  </div>
                </div>
              )}
            />

            {!!errors.isAgreedOnTermsAndConditions && (
              <FormHelperText className={styles['checkbox-error']}>
                {errors.isAgreedOnTermsAndConditions?.message}
              </FormHelperText>
            )}
          </div>

          <div className={styles['checkbox-container']}>
            <Controller
              name="isSubscribedToNewsletter"
              render={({ field }) => (
                <div className={styles['checkbox-content']}>
                  <Checkbox
                    {...field}
                    color="primary"
                    id="isSubscribedToNewsletter"
                    icon={<UncheckIcon />}
                    checkedIcon={<CheckIcon />}
                    disableRipple
                    classes={{
                      root: styles.checkbox
                    }}
                  />
                  <div
                    className={cn(
                      styles['checkbox-label'],
                      styles['checkbox-label-secondary']
                    )}
                  >
                    {t('Common.ActionRequiredModal.EmailSubscriptionLabel')}
                  </div>
                </div>
              )}
            />
          </div>

          <div className={styles.buttons}>
            <Button
              size="medium"
              type="submit"
              color="primary"
              variant="contained"
              className={cn(styles.button)}
            >
              {t('Common.ActionRequiredModal.Confirm')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

import { Dispatch, FC, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { MapTypes } from 'enums';
import { useIsExternalUserDashboard } from 'hooks';
import { useUpdateWidgetFilter } from 'hooks/api';

import { DataGroup } from '../hooks/useMapDataGroups';

import styles from './styles.module.scss';

interface Props {
  mapType: MapTypes;
  widgetId?: string;
  dataGroups: DataGroup[];
  groupFilter: string[];
  setGroupFilter: Dispatch<SetStateAction<string[]>>;
}

export const MapLegend: FC<Props> = ({
  mapType,
  widgetId,
  dataGroups,
  groupFilter,
  setGroupFilter
}) => {
  const { dashboardId } = useParams();
  const { mutate: updateWidgetsFiltersMutation } = useUpdateWidgetFilter({
    dashboardId: dashboardId || '',
    widgetId: widgetId || ''
  });
  const isExternalUserDashboard = useIsExternalUserDashboard();

  const handleLegendItemClick = (label: string, hidden: boolean) => () => {
    let newFilters: string[] = [];

    if (hidden) {
      newFilters = [...groupFilter, label];
    } else {
      newFilters = groupFilter.filter((item) => item !== label);
    }

    setGroupFilter(newFilters);

    if (isExternalUserDashboard || !dashboardId || !widgetId) {
      return;
    }

    updateWidgetsFiltersMutation({ filters: newFilters, mapType });
  };

  return (
    <div className={cn(styles.legend)}>
      {dataGroups.map(({ label, name, isOther }, index) => {
        const filterName = isOther ? name : label;
        const hidden = !groupFilter.includes(filterName);

        return (
          <span
            key={label}
            onClick={handleLegendItemClick(filterName, hidden)}
            className={cn(
              styles['legend-item'],
              styles[`legend-item-${index + 1}`],
              isOther && styles['legend-item-other'],
              hidden && styles.hidden
            )}
          >
            {label}
          </span>
        );
      })}
    </div>
  );
};

import mixpanel from 'mixpanel-browser';

const shouldInitialize = process.env.NODE_ENV === 'production';

if (shouldInitialize && process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    persistence: 'localStorage'
  });
}

export enum MixpanelEvents {
  Signin = 'Signin',
  Signup = 'Signup',
  Logout = 'Logout',
  AuthWithGoogle = 'Auth With Google',
  UpdateUserInfo = 'Update User Info',
  AccountActivation = 'Account activation',
  AccountDeactivation = 'Account deactivation',
  ToggleStatusChanged = 'Toggle Status Changed',
  ResendConfirmationEmail = 'Resend Confirmation Email',
  AnswerFeedback = 'Answer Feedback',
  WidgetFeedback = 'Widget Feedback',
  AccountCreated = 'Account Created',
  AdWidgetClick = 'Ad-Widget Click',
  QuestionAsked = 'Question Asked',
  DashboardCreated = 'Dashboard Created',
  SuggestionClick = 'General Suggestion Clicked',
  AdditionalDataRequested = 'Additional Data Requested from Chat',
  CRMBoosterRequested = 'CRM Booster Requested from Dashboard',
  ContactGieniClick = 'Contact Gieni Clicked',
  DatalakeRequested = 'Report Requested from Dashboard',
  NewChatClick = 'Create new chat Clicked',
  FilterClick = 'Filter Clicked',
  ConfirmFilterClick = 'Confirm Filter Clicked',
  CancelFilterClick = 'Cancel Filter Clicked',
  GoPricingClick = 'Go to Pricing Clicked (0 dashboards)',
  CancelGoPricingClick = 'Filter Cancel Clicked (0 dashboards)',
  OpenRequestReportModal = 'Open Request Report Modal',
  CloseRequestReportModal = 'Close Request Report Modal',
  OpenRequestCRMBoosterModal = 'Open Request CRM Booster Modal',
  CloseRequestCRMBoosterModal = 'Close Request CRM Booster Modal',
  RequestForFreeSnippet = 'Request for Free Snippet',
  SuccessfulRequestForFreeSnippet = 'Successful Request for Free Snippet',
  UnsuccessfulRequestForFreeSnippet = 'Unsuccessful Request for Free Snippet',
  SuccessfulRequestForFreeSnippetCTAClick = 'Successful Request for Free Snippet CTA Click',
  RequestCRMBooster = 'Request CRM Booster',
  SuccessfulRequestCRMBooster = 'Successful Request CRM Booster',
  SuccessfulRequestCRMBoosterCTAClick = 'Successful Request CRM Booster CTA Click',
  RequestForFullReport = 'Request for Full Report',
  SuccessfulRequestForFullReport = 'Successful Request for Full Report',
  UnsuccessfulRequestForFullReport = 'Unsuccessful Request for Full Report',
  SuccessfulRequestForFullReportCTAClick = 'Successful Request for Full Report CTA Click',
  UnsuccessfulRequestForFullReportCTAClick = 'Unsuccessful Request for Full Report CTA Click',
  TalkWithAnExpertRequest = 'Talk with an Expert Request',
  RequestReportClick = 'Request Report Click',
  DownloadReportClick = 'Download Report Click',
  ViewReportClick = 'View Report Click',
  PricingPageRequestFreeSnippet = 'Pricing Page Request Free Snippet',
  PricingPageRequestFullReport = 'Pricing Page Request Full Report',
  PricingPageRequestCRMBooster = 'Pricing Page Request CRM Booster'
}

export default shouldInitialize ? mixpanel : null;

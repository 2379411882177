import { AxiosResponse } from 'axios';
import { Report } from 'entities/Report.entity';

import { ApiBase } from '../ApiBase';

export interface FetchReportsFilters {
  title?: string | null;
  limit?: number;
  offset?: number;
}

class ReportsApi extends ApiBase {
  // eslint-disable-next-line class-methods-use-this
  fetchReports(filters: FetchReportsFilters): Promise<AxiosResponse<Report[]>> {
    // console.log('filters', filters);
    return this.client.get<Report[]>('', {
      params: filters
    });

    // return new Promise((resolve) => {
    //   const data: Report[] = [
    //     {
    //       id: '1',
    //       userId: '1',
    //       title: 'CNC manufacturing in Germany',
    //       description: 'lorem ipsum dolor sit amet',
    //       createdAt: new Date(),
    //       filename: 'cnc-manufacturers-in-germany-2024',
    //       mimetype: 'pdf'
    //     },
    //     {
    //       id: '2',
    //       userId: '1',
    //       title: 'CNC manufacturing in Germany',
    //       description: 'lorem ipsum dolor sit amet',
    //       createdAt: new Date(),
    //       filename: 'cnc-manufacturers-in-germany-2024',
    //       mimetype: 'pdf'
    //     },
    //     {
    //       id: '3',
    //       userId: '1',
    //       title: 'CNC manufacturing in Germany',
    //       description: 'lorem ipsum dolor sit amet',
    //       createdAt: new Date(),
    //       filename: 'cnc-manufacturers-in-germany-2024',
    //       mimetype: 'pdf'
    //     },
    //     {
    //       id: '4',
    //       userId: '1',
    //       title: 'CNC manufacturing in Germany',
    //       description: 'lorem ipsum dolor sit amet',
    //       createdAt: new Date(),
    //       filename: 'cnc-manufacturers-in-germany-2024',
    //       mimetype: 'pdf'
    //     }
    //   ];

    //   resolve({ data } as AxiosResponse<Report[]>);
    // });
  }

  downloadReport(id: string) {
    return this.client.get<Report[]>(`/${id}/download`);
  }

  requestFreeSnippet(body: { question: string; industry?: string }) {
    return this.client.post<void>('/free-snippet', body);
  }

  checkFreeSnippetAvailability() {
    return this.client.head<void>('/check-free-snippet');
  }
}

const instance = new ReportsApi('/reports');

export { instance as ReportsApi };

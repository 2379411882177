export enum FilterTypeEnum {
  Machines = 'treemap',
  Products = 'product_hierarchy',
  Industries = 'industry_hierarchy',
  MaterialsPie = 'materials_pie',
  TechnologiesPie = 'technologies_pie',
  Map = 'map',
  Regions = 'regions',
  EmployeesNumber = 'employees_number',
  Technologies = 'technologies',
  Materials = 'materials',
  MachinesNumber = 'machines_number'
}

export const FilterTypeLabel = {
  [FilterTypeEnum.Machines]: 'Page.Dashboard.Filters.Machines',
  [FilterTypeEnum.Products]: 'Page.Dashboard.Filters.Products',
  [FilterTypeEnum.Industries]: 'Page.Dashboard.Filters.Industries',
  [FilterTypeEnum.MaterialsPie]: 'Page.Dashboard.Filters.MaterialsPie',
  [FilterTypeEnum.TechnologiesPie]: 'Page.Dashboard.Filters.TechnologiesPie',
  [FilterTypeEnum.Map]: 'Page.Dashboard.Filters.Regions',
  [FilterTypeEnum.Regions]: 'Page.CRMBooster.Regions',
  [FilterTypeEnum.EmployeesNumber]: 'Page.CRMBooster.EmployeesNumber',
  [FilterTypeEnum.Technologies]: 'Page.CRMBooster.Technologies',
  [FilterTypeEnum.Materials]: 'Page.CRMBooster.Materials',
  [FilterTypeEnum.MachinesNumber]: 'Page.CRMBooster.MachinesNumber'
};

export const FilterTypeColor = {
  [FilterTypeEnum.Machines]: '#007727',
  [FilterTypeEnum.Products]: '#FFB648',
  [FilterTypeEnum.Industries]: '#FFF3DF',
  [FilterTypeEnum.MaterialsPie]: '#00D878',
  [FilterTypeEnum.TechnologiesPie]: '#FFA51C',
  [FilterTypeEnum.Map]: '#B5F2D0',
  [FilterTypeEnum.Regions]: '#007727',
  [FilterTypeEnum.EmployeesNumber]: '#FFB648',
  [FilterTypeEnum.Technologies]: '#FFA51C',
  [FilterTypeEnum.Materials]: '#00D878',
  [FilterTypeEnum.MachinesNumber]: '#B5F2D0'
};

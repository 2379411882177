import { ApiError } from 'entities/ApiError.entity';
import {
  CRMBoosterData,
  SalesforceApi
} from 'services/API/Salesforce/ReportsApi';

export const requestCRMBooster = async ({
  data
}: {
  data: CRMBoosterData;
}): Promise<void> => {
  try {
    const response = await SalesforceApi.requestCRMBooster(data);

    return response.data;
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { CheckoutFooter, Loader } from 'components';
import { useCreateReport } from 'hooks/api';
import { initializeStripe } from 'utils/stripeUtils';

import styles from './styles.module.scss';

let stripePromise: Promise<Stripe | null> | null = null;

export const ReportCheckout: FC = () => {
  const [searchParams] = useSearchParams();
  const [clientSecret, setClientSecret] = useState('');

  const industry = searchParams.get('industry');
  const question = searchParams.get('question');

  const onSuccess = (secret: string) => {
    setClientSecret(secret);
  };

  const { mutate: createReport, isPending } = useCreateReport(onSuccess);

  useEffect(() => {
    stripePromise = initializeStripe(stripePromise);
  }, []);

  useEffect(() => {
    if (industry || question) {
      createReport({
        industry: industry || 'test',
        question: question || ''
      });
    }
  }, [industry, question, createReport]);

  const options = { clientSecret };

  return (
    <>
      <div className={styles.container}>
        {isPending && <Loader loaderClassName={styles.loader} />}
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      <CheckoutFooter />
    </>
  );
};

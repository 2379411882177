import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { TitleWithBackButton } from 'components';

import { ProfileBilling } from './ProfileBilling';
import { ProfileSettings } from './ProfileSettings';

import styles from './styles.module.scss';

interface TabData {
  label: string;
  slug: string;
}

export enum ProfileTabs {
  Settings = 'settings',
  Billing = 'billing'
}

const DEFAULT_TAB = '/profile/settings';

export const Profile: FC = () => {
  const { t } = useTranslation();
  const { tab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tab) navigate(DEFAULT_TAB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs: TabData[] = [
    {
      label: t('Page.Profile.Tabs.Profile'),
      slug: ProfileTabs.Settings
    },
    {
      label: t('Page.Profile.Tabs.Billing'),
      slug: ProfileTabs.Billing
    }
  ];

  const renderTab = ({ label, slug }: TabData) => {
    const isActive = slug === tab;

    return (
      <Link
        key={label}
        to={`/profile/${slug}`}
        className={cn(styles.tab, isActive && styles.active)}
      >
        {label}
      </Link>
    );
  };

  const renderTabContent = () => {
    switch (tab) {
      case ProfileTabs.Billing:
        return <ProfileBilling />;
      case ProfileTabs.Settings:
      default:
        return <ProfileSettings />;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <TitleWithBackButton
            className={styles.title}
            title={t('Page.Profile.Title')}
          />
          <div className={styles.tabs}>{tabs.map(renderTab)}</div>

          {renderTabContent()}
        </div>
      </div>
    </>
  );
};

import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { useLanguage, useMobile } from 'hooks';
import type { SelectedFilterItem } from 'types';

import {
  renderZoomableSunburstChart,
  ZoomableSunburstData
} from './renderZoomableSunburstChart';

import './styles.scss';

interface Props {
  data: DashboardParamsData;
  selectItem: (selectedItem: SelectedFilterItem) => void;
}

const width = 500;
const height = width;

export const ZoomableSunburst: FC<Props> = ({ data, selectItem }) => {
  const { t } = useTranslation();
  const svgRef = useRef<SVGGElement>(null);
  const isMobile = useMobile();
  const preferredLanguage = useLanguage();

  const modifiedData = {
    name: data.name,
    children: data.children
  } as ZoomableSunburstData;

  useEffect(() => {
    const svgSelection = d3.select(svgRef.current);

    renderZoomableSunburstChart({
      width,
      isMobile,
      selectItem,
      data: modifiedData,
      svgSelection,
      preferredLanguage,
      translations: {
        backTo: t('Page.Dashboard.ZoomableSunburst.BackTo'),
        prevLevel: t('Page.Dashboard.ZoomableSunburst.PrevLevel')
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <svg
      className="zoomable-sunburst"
      // @ts-expect-error svg type
      ref={svgRef}
      style={{
        maxHeight: height,
        maxWidth: width
      }}
      viewBox={`${-width / 2}, ${-height / 2}, ${width}, ${width}`}
    />
  );
};

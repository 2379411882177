import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIconOutlined } from 'assets/CrossIconOutlined.svg';
import { useChatAnnouncement } from 'hooks/api/useChatAnnouncement';
import { useIsFreeSnippetAvailable } from 'hooks/useIsFreeSnippetAvailable';

import styles from './styles.module.scss';

interface Props {
  isActive: boolean;
}

export const AnnouncementBar: FC<Props> = ({ isActive }) => {
  const { data: isFreeSnippetAvailable } = useIsFreeSnippetAvailable();
  const { t } = useTranslation();
  const { data } = useChatAnnouncement(isFreeSnippetAvailable);
  const [isClosed, setClosed] = useState(false);
  const now = new Date();
  const isExpired = data?.dueDate ? now >= new Date(data.dueDate) : true;

  const predefinedAnnouncements = useMemo(
    () => ({
      Report: t('Page.Chat.AnnouncementBar.Report')
    }),
    [t]
  );

  const getAnnouncementBody = (): string | undefined => {
    if (data?.body && !isExpired) return data.body;

    if (isFreeSnippetAvailable) {
      return predefinedAnnouncements.Report;
    }
  };

  const announcementBody = getAnnouncementBody();

  const isAnnouncementVisible = announcementBody && isActive && !isClosed;

  const handleCloseClick = useCallback(() => {
    setClosed(true);
  }, []);

  return isAnnouncementVisible ? (
    <div className={styles.container}>
      <div
        className={styles.content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: announcementBody }}
      />
      <CrossIconOutlined onClick={handleCloseClick} className={styles.close} />
    </div>
  ) : null;
};

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { WidgetEmptyState } from 'components';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { ChartUnitTypes } from 'enums';
import { useUserInfo } from 'hooks/api';
import {
  Cell,
  Pie as PieComponent,
  PieChart as PieChartComponent,
  ResponsiveContainer,
  Sector,
  Tooltip
} from 'recharts';
// eslint-disable-next-line import/no-unresolved
import { PieSectorDataItem } from 'recharts/types/polar/Pie';
import type { SelectedFilterItem } from 'types';

import { PieTooltip } from './PieTooltip';

import './styles.scss';
import styles from './styles.module.scss';

export const WARNING_COLORS = [
  '#fb8a00',
  '#ff9600',
  '#ffa51c',
  '#ffb648',
  '#ffcb7d',
  '#ffe0b0',
  '#fff3df'
];

export const SUCCESS_COLORS = [
  '#00be56',
  '#00ce61',
  '#00d878',
  '#12e192',
  '#7deab2',
  '#b5f2d0',
  '#e1faec'
];

export const PieChartThemeColors = Object.freeze({
  [ChartUnitTypes.MaterialsPie]: SUCCESS_COLORS,
  [ChartUnitTypes.TechnologiesPie]: WARNING_COLORS
});

const renderActiveShape =
  (
    selectedItem: SelectedFilterItem,
    activeIndex: number | undefined,
    {
      innerRadiusIndentation,
      outerRadiusIndentation
    }: { innerRadiusIndentation: number; outerRadiusIndentation: number }
  ) =>
  (props: PieSectorDataItem) => {
    const {
      cx = 0,
      cy = 0,
      innerRadius = 0,
      outerRadius = 0,
      startAngle,
      endAngle,
      fill
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          cornerRadius={0}
          endAngle={endAngle}
          fill={fill}
        />
        {(activeIndex !== undefined || selectedItem) && (
          <Sector
            cx={cx}
            cy={cy}
            cornerRadius={0}
            endAngle={endAngle}
            startAngle={startAngle}
            innerRadius={outerRadius - outerRadiusIndentation}
            outerRadius={outerRadius - innerRadiusIndentation}
            fill={fill}
          />
        )}
      </g>
    );
  };

interface Props {
  fullWidth?: boolean;
  activeIndex?: number;
  chartClassname?: string;
  data?: DashboardParams;
  chartMeasurements?: {
    activeSector: {
      innerRadiusIndentation: number;
      outerRadiusIndentation: number;
    };
    full: {
      textY: number;
      startAngle: number;
      cy: string;
      innerRadius: number;
      outerRadius: number;
    };
    half: {
      textY: number;
      startAngle: number;
      cy: string;
      innerRadius: number;
      outerRadius: number;
    };
  };
  selectedItem: SelectedFilterItem;
  changeActiveIndex: (index?: number) => void;
  setSelectedItem: (selectedItem: SelectedFilterItem) => void;
}

const defaultChartMeasurements = {
  activeSector: {
    innerRadiusIndentation: 35,
    outerRadiusIndentation: 31
  },
  full: {
    textY: 10,
    startAngle: -360,
    cy: '50%',
    innerRadius: 65,
    outerRadius: 95
  },
  half: {
    textY: -10,
    startAngle: 180,
    cy: '70%',
    innerRadius: 75,
    outerRadius: 105
  }
};

export const Pie: FC<Props> = ({
  data,
  chartClassname,
  activeIndex,
  fullWidth,
  changeActiveIndex,
  selectedItem,
  chartMeasurements = defaultChartMeasurements,
  setSelectedItem
}) => {
  const chartColor = data?.type
    ? // @ts-expect-error type error
      PieChartThemeColors[data?.type]
    : PieChartThemeColors.materials_pie;

  const selectItem = (data: SelectedFilterItem) => {
    setSelectedItem(data);
  };

  const { data: userInfo } = useUserInfo();

  const mappedData = (data?.labels || []).map((label, index) => ({
    name: label,
    value: data?.values?.[index]
  }));

  const showHalfPieChart = (data?.values?.length || 0) < 4;
  const { cy, textY, startAngle, outerRadius, innerRadius } = showHalfPieChart
    ? chartMeasurements.half
    : chartMeasurements.full;

  const generalLabel = useMemo(() => {
    if (!selectedItem) {
      return '100%';
    }

    return `${(typeof selectedItem?.value === 'number'
      ? selectedItem.value
      : 1
    ).toFixed(1)}%`;
  }, [selectedItem]);

  const onMouseEnter = (_data: unknown, index: number) => {
    changeActiveIndex(index);
  };

  const onMouseLeave = () => {
    changeActiveIndex(undefined);
  };

  const handleRemoveTooltip = useCallback(
    (event: MouseEvent) => {
      const isPieChartSector = (
        event?.target as SVGPathElement
      )?.classList?.contains('recharts-sector');

      if (!isPieChartSector) {
        changeActiveIndex(undefined);
      }
    },
    [changeActiveIndex]
  );

  const onClick = (data: {
    payload: { payload: { name: string; value: number } };
  }) => {
    if (userInfo?.deactivatedAt) return null;

    const selected = data?.payload?.payload;
    const selectedItemData = {
      name: selected?.name,
      code: selected?.name,
      value: selected?.value
    };

    selectItem(selectedItemData);
  };

  useEffect(() => {
    document.body.addEventListener('mouseover', handleRemoveTooltip);

    return () => {
      document.body.removeEventListener('mouseover', handleRemoveTooltip);
    };
  }, [handleRemoveTooltip]);

  return (
    <>
      {mappedData?.length ? (
        <div
          className={cn(
            styles['chart-container'],
            fullWidth && styles['full-width']
          )}
        >
          <div className={cn(styles['pie-chart-container'], chartClassname)}>
            <ResponsiveContainer>
              <PieChartComponent className={styles['pie-chart']}>
                <Tooltip
                  wrapperStyle={{
                    display: activeIndex !== undefined ? 'block' : 'none'
                  }}
                  cursor={false}
                  position={{ y: -50 }}
                  content={<PieTooltip />}
                />
                <PieComponent
                  // eslint-disable-next-line react/no-unstable-nested-components
                  label={(props: { cx: number; cy: number }) => {
                    return (
                      <g>
                        <text
                          x={props.cx}
                          fill="#161616"
                          textAnchor="middle"
                          y={props.cy + textY}
                          className={styles['total-value']}
                        >
                          {generalLabel}
                        </text>
                      </g>
                    );
                  }}
                  cy={cy}
                  cx="50%"
                  radius={4}
                  endAngle={0}
                  startAngle={startAngle}
                  activeIndex={activeIndex}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  fill="#8884d8"
                  dataKey="value"
                  paddingAngle={1}
                  data={mappedData}
                  cornerRadius={0}
                  onClick={onClick}
                  className={styles.pie}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  activeShape={renderActiveShape(
                    selectedItem,
                    activeIndex,
                    chartMeasurements.activeSector
                  )}
                >
                  {(mappedData || []).map((entry, index) => (
                    <Cell
                      strokeWidth={0}
                      key={`cell-${entry.name}`}
                      fill={
                        index < 7
                          ? chartColor[index % chartColor.length]
                          : chartColor[6]
                      }
                    />
                  ))}
                </PieComponent>
              </PieChartComponent>
            </ResponsiveContainer>
          </div>
          <div className={styles.rows}>
            {mappedData.map((row, index) => (
              <div key={row.name} className={styles.row}>
                <div className={cn(styles['label-container'])}>
                  <span
                    className={styles.color}
                    style={{
                      backgroundColor:
                        index < 7
                          ? chartColor[index % chartColor.length]
                          : chartColor[6]
                    }}
                  />
                  <span className={styles.label}>{row.name}</span>
                </div>
                <span className={styles.value}>{`${(typeof row.value ===
                'number'
                  ? row.value
                  : 1
                ).toFixed(1)}%`}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </>
  );
};
